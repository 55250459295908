import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth.service';
import { Chapter } from 'src/app/models/chapter.models';
import { ChartService } from 'src/app/services/charts/charts.service';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
import { UserService } from 'src/app/services/users/users.service';
import { PageService } from './../../../services/page.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IPage } from 'src/app/models/page.models';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  circleProgressOption: any = {
    radius: 50,
    space: -8,
    outerStrokeGradient: false,
    outerStrokeWidth: 8,
    outerStrokeColor: "#11CEA0",
    innerStrokeColor: "#E5E5E5",
    innerStrokeWidth: 8,
    animationDuration: 1000,
    showBackground: false,
    clockwise: true,
    startFromZero: true,
    lazy: true,
    titleColor: 'rgba(55, 64, 82, .5)',
    unitsColor: 'rgba(55, 64, 82, .5)',
    unitsFontSize: '14px',
    titleFontWeight: 'bold',
    showSubtitle: false,
  }

  chapters: Array<any> = [];
  show_charts: boolean = false;
  userData: any;
  decode_token: any;
  userProgress: any = {};

  // CAROUSEL
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 600,
    nav: false,
    items: 4,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      576: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      },
      1366: {
        items: 4
      }
    },

  }

  constructor(
    private meta: MetadataService,
    private userService: UserService,
    private authService: AuthService,
    private chartService: ChartService,
    private cookieService: CookieService,
    private pageService: PageService
  ) { }

  ngOnInit(): void {
    let token = this.cookieService.get("gr_access");
    this.decode_token = this.authService.getDecodedAccessToken(token);
    this.getUserData();
    this.getUserParams();
    this.getUserProgress();
  }

  getUserData() {
    this.userService.getUserProfileData(this.decode_token.user_id).subscribe((user_data: any) => {
      this.userData = user_data;
    });
  }

  getUserParams() {
    this.userService.getUserParameters(this.decode_token.user_id).subscribe((user_params: any) => {
      this.show_charts = true;
      this.chartService.setChartsData(user_params);
    })
  }

  getUserProgress() {
    this.pageService.getPages().subscribe((season: any) => {
      this.userProgress = season[0].progress;
      this.chapters = season[0].pages;

      if (this.chapters.length) {
        const isSubscribed = this.userData.isSubscribed
        this.chapters.forEach((chapter: IPage, idx: number) => {
          chapter._enabled = false
          if (isSubscribed) {
            console.debug('Subscrito')
            if (idx === 0) { //FIRST
              chapter._enabled = true
            } else {
              chapter._enabled = this.chapters[idx - 1].progress.progress_percentage === 100
            }
          } else {
            // // TODO: IMPROVE FLEXIBILITY BY is_preliminar_limit
            chapter._enabled = idx === 0
            // console.debug('Sin subscripcion')
          }
        })
      }
    })
  }

}
