<div *ngIf="stepToSignUp === 'SIGNUP'">
  <app-card>
    <app-modal></app-modal>
    <div class="" card__header>
      <h1 class="font-header">Nueva cuenta</h1>
    </div>

    <div class="form-wrapper" card__content>
      <form (ngSubmit)="onSubmit()" [formGroup]="signUpForm" class="form">

        <div class="group">

          <div class="input-wrapper">
            <label for="first_name" class="text-start">Nombre</label>

            <span *ngIf="formErrors?.['first_name']" class="text-start d-block font-14 text-error">
              {{formErrors?.['first_name']?.label}}
            </span>

            <input name="first_name" id="first_name" class="input" type="text" placeholder="Nombre"
              formControlName="first_name" />
          </div>

          <div class="input-wrapper">
            <label for="last_name" class="text-start">Apellido</label>

            <span *ngIf="formErrors?.['last_name']" class="text-start d-block font-14 text-error">
              {{formErrors?.['last_name']?.label}}
            </span>

            <input id="last_name" name="last_name" class="input" type="text" placeholder="Apellido"
              formControlName="last_name" />
          </div>

          <div class="input-wrapper">
            <label for="phone" class="text-start">Teléfono</label>

            <span *ngIf="formErrors?.['phone']" class="text-start d-block font-14 text-error">
              {{formErrors?.['phone']?.label}}
            </span>

            <input id="phone" name="phone" class="input" type="text" placeholder="Teléfono" formControlName="phone" />
          </div>
        </div>

        <div class="input-wrapper">
          <label for="email" class="text-start">Correo Electrónico</label>

          <span *ngIf="formErrors?.['email']" class="text-start d-block font-14 text-error">
            {{formErrors?.['email']?.label}}
          </span>

          <input id="email" name="email" class="input" type="email" placeholder="Dirección de correo electrónico"
            formControlName="email" />
        </div>

        <div class="input-wrapper">
          <label for="password" class="text-start">Contraseña</label>

          <span *ngIf="formErrors?.['password']" class="text-start d-block font-14 text-error">
            {{formErrors?.['password']?.label}}
          </span>

          <input id="password" name="password" class="input" type="password" placeholder="Contraseña"
            formControlName="password" />
        </div>

        <div class="input-wrapper">
          <label for="confirmPassword" class="text-start">Confirmar Contraseña</label>

          <span *ngIf="formErrors?.['confirmPassword']" class="text-start d-block font-14 text-error">
            {{formErrors?.['confirmPassword']?.label}}
          </span>

          <input id="confirmPassword" class="input" type="password" name="confirmPassword"
            placeholder="Verificar Contraseña" formControlName="confirmPassword" />
        </div>


        <div>
          <span *ngIf="formErrors?.['acceptTerms']" class="text-start d-block font-14 text-error">
            {{formErrors?.['acceptTerms']?.label}}
          </span>

          <div class="checkbox-wrapper">
            <input id="acceptTerms" name="acceptTerms" class="input" type="checkbox" formControlName="acceptTerms" />

            <label for="remember-me" class="text-grey font-regular">He leído y estoy de acuerdo con los <a
                class="text-purple-light link" routerLink="/signup">términos y
                condiciones</a> de la página web.
            </label>
          </div>
        </div>

        <div class="actions-wrapper mt-2">
          <button type="submit" class="text-white bg-red btn font-btn g-recaptcha">
            <span class="text-white" *ngIf="!isLoading; else loading">
              Registrarme
            </span>

            <ng-template #loading>
              <app-loading></app-loading>
            </ng-template>
          </button>
        </div>
      </form>

      <span class="text-grey font-regular">
        ¿Ya tienes una cuenta? <a class="text-purple-light link" routerLink="/signin">Inicia sesión</a>
      </span>
    </div>
  </app-card>
</div>

<div *ngIf="stepToSignUp === 'SIGNUP_CONFIRM'" class="max-w-lg">
  <app-card>
    <app-modal></app-modal>

    <div class="" card__header>
      <h1 class="font-header text-center">¡Listo! Revisa tu correo.</h1>
    </div>

    <div class="description text-center" card__content>
      <p class="font-regular text-grey description-content">
        Tu cuenta está en proceso de activación. Verifica tu correo electronico para
        acceder a la plataforma Growth Road. El correo fue enviado a:
      </p>

      <p class="font-16 text-black font-bold description-email">{{userToRegister?.email}}</p>

      <button [disabled]="!!isLoading" type="button" class="bg-red btn btn--outline font-btn w-100 mt-2"
        (click)="reSendEmail()">
        <span class="text-white">
          Reenviar correo
        </span>
      </button>
    </div>
  </app-card>
</div>
