<app-card *ngIf="isLoading">
  <div card__content>
    <app-loading color="#de377c"></app-loading>
  </div>
</app-card>


<app-card *ngIf="!isLoading">
  <app-modal></app-modal>
  
  <div class="" card__header>
    <span class="font-header text-center">
      {{ isEmailVerified ? '¡Email Verificado!' : '¡Hubo un Error!' }}
    </span>
  </div>
  
  <div card__content>
    <a
      routerLink="/dashboard"
      class="text-white bg-red btn font-btn g-recaptcha w-100 block mt-2"
    >
      Continuar
    </a>
  </div>
</app-card>
