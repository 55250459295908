import { ToastService } from 'src/app/utils/toast.service';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { Component, OnInit } from '@angular/core';
import { Feature } from 'src/app/models/feature.models';

@Component({
  selector: 'app-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss']
})

export class MembershipCardComponent implements OnInit {
  features: Array<Feature> = [
    {
      title: 'Acceso a todos los capítulos de orientación vocacional de nueva generación',
      content: '(video curso)'
    },
    {
      title: 'Orientación Académica',
      content: "individualizada, realizada por nuestro algoritmo de inteligencia artificial."
    },
    {
      title: 'El reporte Growth Road incluye:',
      content: '',
      subitems: [
        {
          title: 'Estudio vocacional del alumno(personalidad, inteligencia emocional, inteligencias múltiples, intereses vocacionales, capacidades profesionales y estilos de aprendizaje).'
        },
        {
          title: 'Estudio del perfil académico del alumno(5 opciones de grado universitario y 5 de formación profesional adaptadas al alumno)'
        },
        {
          title: 'Informe completo en pdf para descargar, imprimir y compartir.'
        }
      ]
    },
    {
      title: 'Acceso completo a Growth Road Experiences',
      content: "donde encontrarás actividades solidarias, prácticas en empresas, experiencias en universidades..."
    },
  ]
  productPrice: string | null = null

  constructor(private paymentService: PaymentService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.getProducts()
  }

  getProducts() {
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    this.paymentService.getPaymentsProducts().subscribe({
      next: (value: any) => {
        if (value.length) {
          var usd = new Intl.NumberFormat("es-SP", { style: "currency", "currency": "EUR" }).format(value[0].price);
          this.productPrice = usd
        }
        this.toastService.closeLoader();
      },
      error: (error) => {
        console.debug(error)
        this.toastService.closeLoader();
      },
    })
  }

}

