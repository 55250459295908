<div class="container" [class.container-no-padding]="checkout">
    <!-- <h5 class="color-blue"><b>Mis medios de pago</b></h5> -->
    <!-- <br> -->
    <div class="payments" *ngIf="!new_payment">
        <div class="card" *ngFor="let payment_method of payment_methods" 
            (click)="checkout ?  selectPaymentForCheckout(payment_method) : null" 
            [class.card-checkout]="checkout" 
            [class.card-selected]="checkout && payment_method.use_for_checkout">
            <div class="custom-check" *ngIf="checkout">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
                [checked]="payment_method.use_for_checkout" 
                (change)="selectPaymentForCheckout(payment_method)">
                <label class="form-check-label" for="flexRadioDefault1"></label>
            </div>
            <h5 class="font-18 font-primary--b"><span *ngIf="payment_method.is_default" [class.default-checkout]="checkout"> Predeterminada </span> </h5>
            <div class="payment_content">
                <img *ngIf="payment_method.brand == 'mastercard'" src="../../../../../../assets/images/icons/master-card-logo.png">
                <img *ngIf="payment_method.brand == 'visa'" src="../../../../../../assets/images/icons/VISA-logo-color.jpeg">
                <img *ngIf="payment_method.brand == 'amex'" src="../../../../../../assets/images/icons/amex-logo.png">
                <img *ngIf="payment_method.brand == 'diners'" src="../../../../../../assets/images/icons/diners-logo.png">
                <img *ngIf="payment_method.brand == 'discover'" src="../../../../../../assets/images/icons/discover-logo.png">
                <img *ngIf="payment_method.brand == 'unionpay'" src="../../../../../../assets/images/icons/union-pay-logo.png">
                <img *ngIf="payment_method.brand == 'jcb'" src="../../../../../../assets/images/icons/jcb-logo.png">
                <p class="font-17">  
                    {{ payment_method.brand | titlecase }}  •••• {{ payment_method.last4 }}<br>
                    <small class="text-grey font-14 opacity-45">Vence {{ payment_method.exp_month }}/{{ payment_method.exp_year }}</small>
                </p>
                <div class="buttons" *ngIf="!checkout">
                    <button class="btn" *ngIf="!payment_method.is_default" (click)="setDefaultCard(payment_method)"> Marcar como predeterminada </button>
                    <button class="btn btn-outline" (click)="deleteCard(payment_method)"> Eliminar </button>
                </div>
            </div>
        </div>
        <div class="card new-card text-center" (click)="new_payment = true">
            <i class="bi bi-credit-card-2-front text-blue-light font-36"></i>
            <p class="text-blue-light font-15"> + Agregar nuevo medio de pago </p>
        </div>
    </div>
    <div *ngIf="new_payment">
        <app-new-payment-method [checkout]="checkout" (close)="new_payment = false" (done)="getPaymentMethods(false,$event);new_payment = false"></app-new-payment-method>
    </div>
</div>