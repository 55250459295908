import { OptionActivity } from 'src/app/models/activities/option.models';
import { generateID } from 'src/app/utils/fakers';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-regular-valoration-activity',
  templateUrl: './regular-valoration-activity.component.html',
  styleUrls: ['./regular-valoration-activity.component.scss']
})
export class RegularValorationActivityComponent implements OnInit, AfterContentInit {
  @Input() video?: string

  @Input() statement: string = '¿Lorem ipsum?'
  selectedValue: number = 1
  @Input() options: Array<OptionActivity> = []
  @Output() dataEvent = new EventEmitter<any>();

  @Input() edit: any = []

  constructor() { }
  ngAfterContentInit(): void {
    if (this.edit.length) {
      this.options.forEach((option: any) => {
        if (this.edit[0].answer == option.id) {
          option.selected = true
        }
      });
      this.dataHandler(this.options)
    }
  }

  ngOnInit(): void {
    this.initOptions()
  }

  initOptions() {
    const cleanOptions: Array<OptionActivity> = []
    this.options!.forEach((option, idx) => {
      cleanOptions.push({
        id: option.id,
        body: option.body,
        question: option.question,
        selected: false,
      })
    })
    this.options = cleanOptions
  }

  dataHandler(value: any) {
    this.dataEvent.emit(value);
  }

  updateRadio(index: number) {
    this.options.forEach(value => {
      value.selected = false
    });
    this.options[index].selected = true

    this.dataHandler(this.options)
  }

}
