<app-card>
  <app-modal></app-modal>
  <div class="" card__header>
    <h1 class="font-header">Iniciar sesión en Growth Road</h1>
  </div>
  <div class="form-wrapper" card__content>
    <form action="" class="form">
      <div>
        <label>Correo electrónico</label>
        <input [(ngModel)]="login_data.email" name="email" class="input" type="text" placeholder="Correo electrónico">
      </div>
      <div>
        <label>Contraseña</label>
        <input [(ngModel)]="login_data.password" name="password" class="input"
          [type]="show_password ? 'text' :'password'" placeholder="Contraseña">
        <i class="bi bi-eye-fill" [class.bi-eye-fill]="!show_password" [class.bi-eye-slash-fill]="show_password"
          (click)="show_password = !show_password"></i>
      </div>
    </form>
    <div class="group">
      <a routerLink="/recovery" class="text-purple-light link font-regular">¿Has olvidado tu contraseña?</a>
    </div>
    <div class="actions-wrapper">
      <button [disabled]="inProcess || (!login_data.email) || (!login_data.password)" (click)="onSubmit()"
        class=" bg-red btn font-btn">
        <span class="text-white font-26" *ngIf="!inProcess; else loading">
          Iniciar sesión
        </span>
        <ng-template #loading>
          <app-loading></app-loading>
        </ng-template>
      </button>
    </div>


    <span class="font-regular text-grey">
      ¿No tienes una cuenta en Growth Road? <a class="text-purple-light link" routerLink="/signup">Regístrate</a>
    </span>


  </div>
</app-card>