<div class="graph-component type__{{chartId}}">
  <div class="chart-wrapper" [class.chart-wrapper-stats]="stats" [style.height]="chartHeight">
    <canvas #{{chartId}} id="{{ chartId }}" [class.canvas-multiple]="classOverlayMultiple"></canvas>
    <div [class.overlay]="classOverlay" [class.overlay-multiple]="classOverlayMultiple"></div>
  </div>
  <div class="legend-wrapper">
    <div class="legend" *ngFor="let label of doughnutChartLabels; index as i">
      <div class="color-box" [ngStyle]="{backgroundColor: colors[i]}"></div>
      <div class="label">
        {{label}}
      </div>
    </div>
  </div>
</div>