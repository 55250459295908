<main class="season-page page">
  <section *ngIf="inProcess;else loaded">
    <app-card padding="50px 25px" borderRadius="10px">
      <article card__content class="row">
        <div class="col-12 text-center">
          <h2 class="font-24 text-grey opacity-50">
            Cargando espere un momento
          </h2>
        </div>
      </article>
    </app-card>
  </section>
  <ng-template #loaded>
    <h1 class="page-header font-primary--eb font-30 font-w--extra-bold text-center" *ngIf="season !== null">
      Temporada 1: <span class="text-green">{{season.name}}</span>
    </h1>
    <section class="chapters" *ngIf="season !== null">
      <app-chapters-list [chapters]="season.pages" [subscribed]="isSubscribed"></app-chapters-list>
    </section>
    <app-card padding="50px 25px" borderRadius="10px" *ngIf="season === null">
      <article card__content class="row">
        <div class="col-12 text-center">
          <img class="opacity-25 mb-4" src="/assets/images/icons/icon_warning.png" alt="">
          <h2 class="font-18 font-primary--b text-grey opacity-50">
            No se han publicado temporadas aun.
          </h2>
        </div>
      </article>
    </app-card>
  </ng-template>
</main>