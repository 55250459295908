import { generateID } from './../../utils/fakers';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, throwError } from 'rxjs';
import { ActivityType } from 'src/app/models/activity.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  types: Array<ActivityType> = [
    {
      id: generateID(),
      title: 'Texto simple',
      slug: 'text',
      code: 'FREE_TEXT',

    },
    {
      id: generateID(),
      title: 'Video',
      slug: 'video',
      code: 'WATCH',
    },
    {
      id: generateID(),
      title: 'Lista de valores',
      slug: 'list',
      code: 'VALUES_LIST',
    },
    {
      id: generateID(),
      title: 'Selección simple',
      slug: 'simple-selection',
      code: 'SELECT_ANSWER'
    },
    {
      id: generateID(),
      title: 'Selección multiple',
      slug: 'multiple-selection',
      code: 'SELECT_MULTIPLES_ANSWERS'
    },
    {
      id: generateID(),
      title: 'Valoración regular',
      slug: 'regular-valoration',
      code: 'DISCRETE_NUMERIC_ANSWER'
    },
    {
      id: generateID(),
      title: 'Valoración por porcentaje',
      slug: 'percentual-valoration',
      code: 'CONTINUOUS_NUMERIC_ANSWER'
    },
  ]

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  getActivityTypes(): Array<ActivityType> {
    return this.types
  }

  getActivities() {
    const url = environment.apiUrl + '/questionaries/questions/'
    const options = {
      headers: {
        Authorization: 'Bearer ' + this.cookieService.get('gr_access')
      }
    }
    return this.httpClient.get(url, options).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  saveAnswer(answer: { answer: string, questionary: number, question: number }) {
    const url = environment.apiUrl + '/answers/'
    return this.httpClient.post(url, answer).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateAnswer(answer: { answer: string, questionary: number, question: number }, answerId: number) {
    const url = environment.apiUrl + '/answers/' + answerId + '/'
    return this.httpClient.put(url, answer).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
