import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { UserService } from 'src/app/services/users/users.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {

  @Input('profile_data') profile_data: any = {};
  @Output() profileDataChange = new EventEmitter();
  public confirm_profile_image: boolean = false;
  public userProfileForm!: FormGroup<any>;
  public change_email_view: boolean = false;
  public profile_picture: any;
  public profile_picture_file: any;
  constructor(
    private toastService: ToastService,
    private formService: FormService,
    private userService: UserService) { }

  ngOnInit(): void {
    if (this.profile_data) this.setForm();
  }

  setForm() {
    this.userProfileForm = this.formService.getUserProfileForm(this.profile_data);
  }

  onFormSubmit() {
    this.toastService.showLoaderModal('Espere un momento', 'Estamos guardando la información');
    this.userService.editUserProfile(this.profile_data.id, this.userProfileForm.value, this.confirm_profile_image ? this.profile_picture_file : null).subscribe({
      complete: () => {
        this.toastService.closeLoader();
      },
      next: (value) => {
        let email = this.profile_data.email;
        this.userProfileForm.reset(value);
        this.profile_data = { ...value }
        this.profile_data.email = email;
        this.profileDataChange.emit(this.profile_data);
        this.toastService.showNotificationModal('Datos guardados', 'Los datos de perfil han sido actualizados correctamente', 'notification', 'Continuar en Growth Road');
      },
      error: (err) => {
        this.toastService.closeLoader();
        this.toastService.showNotificationModal('Oops!...Algo ha ido mal', 'Por favor verifica tus datos y vuelve a intentarlo', 'notification', 'Volver a intentar');
      },
    })
  }

  _fileSelectedChange(event: any) {
    this.profile_picture_file = event.target.files[0];
    const reader = new FileReader();
    if (event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/jpg' && event.target.files[0].type !== 'image/gif') {
      this.toastService.showNotificationModal('Oops!...Algo ha ido mal', 'Por favor verifica que el archivo seleccionado sea una imagen', 'notification', 'Ok entendido');
    } else {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.profile_picture = reader.result as string;
        this.confirm_profile_image = true;
        this.profile_data.image_profile = this.profile_picture;
      };
    }
  }

  closeChangeEmail(event: any) {
    this.change_email_view = false;
    if (event) this.profile_data.email = event;
  }

}
