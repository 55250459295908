import { generateID } from './../../../utils/fakers';
import { ModalComponent } from '../../core/modal/modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';
import { ToastService } from 'src/app/utils/toast.service';
import { INewUser } from 'src/app/models/user.models';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  signUpForm!: FormGroup
  formErrors: any = null
  isLoading: boolean = false
  userToRegister: any = null
  stepToSignUp: 'SIGNUP' | 'SIGNUP_CONFIRM' = 'SIGNUP'
  reCAPTCHAToken: string = "";

  constructor(
    // private recaptchaV3Service: ReCaptchaV3Service,
    private activeRoute: ActivatedRoute,
    private toastService: ToastService,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.signUpForm = this.initForm()
  }

  errorSubmit() {
    this.toastService.showNotificationModal(
      '¡Hubo un Error!',
      'No se ha podido reenviar el registro',
      'notification',
      'Volver a intentar'
    )
  }

  onSubmit() {
    this.cookieService.delete('gr_access', '/')
    this.cookieService.delete('gr_refresh', '/')
    // this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
    //   this.reCAPTCHAToken = `Token [${token}] generated`;
    // });
    const { errors, totalErrors } = handleFormErrors(this.signUpForm)

    if (totalErrors) {
      console.error('Form Errors', errors)
      this.formErrors = errors
      return
    }

    if (!this.signUpForm.get('acceptTerms')?.value) {
      this.toastService.showNotificationModal(
        'Espera un momento',
        'Para continuar debes aceptar los términos y condiciones de uso',
        'notification',
        'Volver a intentar'
      )

      return
    }

    const { username, first_name, last_name, email, phone, password, confirmPassword } = this.signUpForm.value

    const newUser: INewUser = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      password: password,
      password2: confirmPassword,
      tax_id: null,
      image_profile: null,
      is_suscribed: false,
      user_type: 'Student',
      is_archived: false,
      description: 'epale',
    }

    this.userToRegister = newUser
    this.isLoading = true

    this.authService.signUp(this.userToRegister).subscribe({
      error: (err) => {
        console.debug('SignUp Error: ', err);
        this.isLoading = false
        this.errorSubmit()
      },
      next: (data) => {
        console.log('SignUp Success: ', data)
        this.signUpForm.reset()
      },
      complete: () => {
        this.isLoading = false
        this.stepToSignUp = 'SIGNUP_CONFIRM'
        this.signUpForm.reset()
      }
    })


  }

  initForm(): FormGroup { // TODO apply review patterns validators
    const commonValidators = [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(30),
      // Validators.pattern('^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$')
    ]

    return this.fb.group({
      // username: ['', commonValidators],
      first_name: ['', commonValidators],
      last_name: ['', commonValidators],
      phone: ['', [
        Validators.required,
        // Validators.pattern('^[0-9]{9}$'),
        Validators.minLength(6),
        Validators.maxLength(14)
      ]],
      email: ['', [
        Validators.required,
        Validators.email,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        Validators.minLength(6),
        Validators.maxLength(50)
      ]],
      password: ['', [
        Validators.required,
        // Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$'),
        Validators.minLength(8),
        Validators.maxLength(20)
      ]],
      confirmPassword: ['', [Validators.required]],
      acceptTerms: ['', [Validators.required, Validators.requiredTrue]],
    })
  }

  reSendEmail() {
    this.isLoading = true

    this.authService.signUp(this.userToRegister).subscribe({
      error: (err) => {
        console.debug('SignUp Error: ', err)
        this.errorSubmit()
      },
      next: (data) => console.log('SignUp Success: ', data),
    })

    setTimeout(() => {
      this.isLoading = false
      console.debug('ResendEmail')
    }, 3500)
  }
}
