import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chapter-detail-resume',
  templateUrl: './chapter-detail-resume.component.html',
  styleUrls: ['./chapter-detail-resume.component.scss']
})
export class ChapterDetailResumeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
