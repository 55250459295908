import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, AfterContentInit } from '@angular/core';
import { generateID } from 'src/app/utils/fakers';

@Component({
  selector: 'app-text-activity',
  templateUrl: './text-activity.component.html',
  styleUrls: ['./text-activity.component.scss']
})
export class TextActivityComponent implements AfterContentInit {
  @Input() video?: string

  placeholder: string = 'Escribe algo...'
  @Input() statement: string = '¿Lorem ipsum?'
  _id: string = 'activity-' + generateID()
  @Output() public inputData: string = ''
  @Output() dataEvent = new EventEmitter<string>();

  @Input() edit: any = []

  constructor() { }
  ngAfterContentInit(): void {
    if (this.edit.length) {
      this.inputData = this.edit[0].answer
      this.dataHandler(this.inputData)
    }
  }

  dataHandler(value: any) {
    this.dataEvent.emit(value);
  }

}
