import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-content',
  templateUrl: './modal.component.html'
})
export class ModalContent {
  @Input() content: any;
  @Input() title: any;
  @Input() type: any;
  @Input() action: any;
  @Input() fnAction?: () => void;

  constructor(public activeModal: NgbActiveModal) { }
}

@Component({ selector: 'app-modal', templateUrl: './modal.component.html' })
export class ModalComponent implements OnInit {
  @Input() title!: string
  @Input() content!: string
  @Input() type?: string
  @Input() action?: string
  @Input() fnAction?: () => void;

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  close() {
    const modalRef = this.modalService.dismissAll(ModalContent);
  }

  open(title: string, content: string, type: string = this.type ? this.type : 'error', options?: {}, fnAction?: () => void) {
    const modalRef = this.modalService.open(ModalContent, options);
    if (fnAction !== undefined) {
      modalRef.componentInstance.fnAction = fnAction;
    }
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
  }

}
