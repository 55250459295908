import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  @Input() chapter!: any
  @Input() chapterNumber!: number;
  @Input() isCarousel!: boolean;
  @Input() userSubscribed!: boolean;
  @Input() enable: boolean = true;
  @Input() circleProgressOption: any = {
    radius: 50,
    space: -10,
    outerStrokeGradient: true,
    outerStrokeWidth: 10,
    outerStrokeColor: "#fff",
    innerStrokeColor: "rgba(255,255,255, .5)",
    innerStrokeWidth: 10,
    animationDuration: 1000,
    showBackground: false,
    clockwise: true,
    startFromZero: true,
    lazy: true,
    titleColor: '#fff',
    unitsColor: '#fff',
    unitsFontSize: '14px',
    titleFontWeight: 'bold',
    showSubtitle: false,
  }
  apiUrl = environment.apiUrl
  completed_themes: any;

  constructor() { }

  ngOnInit(): void {
    this.getCompletedThemes()
  }

  getCompletedThemes() {
    this.completed_themes = this.chapter.pages.filter((page: any) => page.progress.progress_percentage == 100).length;
  }

}
