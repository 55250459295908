import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth.service';

@Component({
  templateUrl: './email-verification-page.component.html',
  styleUrls: ['./email-verification-page.component.scss']
})
export class EmailVerificationPageComponent implements OnInit {
  isEmailVerified: boolean = false
  isLoading: boolean = true

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token') as string;
    const emailIdB64 = this.route.snapshot.paramMap.get('emailIdB64') as string;
    const userIdB64 = this.route.snapshot.paramMap.get('userIdB64') as string;

    if (!token || !emailIdB64 || !userIdB64) {
      this.isLoading = false
      return
    }

    this.authService.verifyEmail(token, emailIdB64, userIdB64).subscribe({
      error: (err) => {
        console.error('Ha ocurrido un error en al verificar el correo', err)
        this.isLoading = false
      },
      next: (data: any) => {
        if (!data) {
          this.isLoading = false
          return
        }

        this.cookieService.set('gr_access', data.access)
        this.cookieService.set('gr_refresh', data.refresh)
        this.router.navigate(['/dashboard/home'])
      },
      complete: () => this.isLoading = false
    })
  }
}
