import { EventEmitter } from '@angular/core';
// import { OptionActivity } from './../../../../models/acitivities/option.models';
import { Component, Input, OnInit, Output } from '@angular/core';
import { OptionActivity } from 'src/app/models/activities/option.models';
@Component({
  selector: 'app-multiple-selection-activity',
  templateUrl: './multiple-selection-activity.component.html',
  styleUrls: ['./multiple-selection-activity.component.scss']
})
export class MultipleSelectionActivityComponent implements OnInit {
  @Input() isSimple: boolean = false
  @Input() max: number = 2
  @Input() min: number = 1
  @Input() video?: string
  @Input() statement: string = '¿lorem ipsum?'
  @Input() options?: Array<OptionActivity> | [] = []

  disabledSelection: boolean = false
  selectedOptions: Array<OptionActivity> = []

  @Input() edit: any = []

  @Output() dataEvent = new EventEmitter<any>();

  ngAfterContentInit(): void {
    if (this.edit.length) {
      let answers = this.edit[0].answer.split(',')
      this.options!.forEach(option => {
        answers.forEach((answer: any) => {
          if (option.id == answer) {
            option.value = true
            option.disabled = false
            this.selectedOptions.push({
              id: answer,
              body: "",
              question: option.question,
              value: true,
              disabled: false
            })

          } else {
            if (answers.length == this.max) {
              option.disabled = true
            }
          }
        });
      });
      this.dataHandler(this.selectedOptions)
    }
  }

  constructor() {
  }

  disabling() {
    if (this.selectedOptions.length === this.max) {
      this.options!.forEach(option => {
        if (!option.value) {
          option.disabled = true
        }
      });
    } else {
      console.debug('aun puedes seleccionar')
      this.options!.forEach(option => {
        option.disabled = false
      });
    }
  }

  onChange(option: OptionActivity) {
    this.options!.forEach((opt, i) => {
      if (opt.id == option.id) {
        this.options![i].value = !this.options![i].value
        if (this.options![i].value === true) {
          this.selectedOptions.push(option)
        } else {
          this.selectedOptions = this.selectedOptions!.filter(opt => opt.id != option.id);
        }
      }
    });
    this.disabling()

    this.dataHandler(this.selectedOptions)
  }

  dataHandler(value: any) {
    this.dataEvent.emit(value);
  }

  initOptions() {
    const cleanOptions: Array<OptionActivity> = []
    this.options!.forEach((option) => {
      cleanOptions.push({
        id: option.id,
        body: option.body,
        question: option.question,
        value: false,
        disabled: false,
      })
    })
    this.options = cleanOptions
  }

  ngOnInit(): void {
    this.initOptions()

  }

}
