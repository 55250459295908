<div class="activity-wrapper">
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container mb-3" *ngIf="video">
    <app-video [options]="{
                fluid: true,
                aspectRatio: '16:9',
                autoplay: false,
                controls: true,
                sources: [{
                  src: video,
                  type: 'video/mp4' }]
                }">
    </app-video>
  </div>
  <ul class="list-unstyled selectables">
    <li class="selectable" [class.checked]="option.value" [class.disabled]="option.disabled"
      *ngFor="let option of options">
      <input (change)="onChange(option)" [disabled]="option.disabled" type="checkbox" class="selectable__input"
        id="selectable-{{option.id}}">
      <label class="selectable__label" for="selectable-{{option.id}}">
        <p>{{option.body}}</p>
      </label>
    </li>
  </ul>
</div>
<h3 *ngIf="!options!.length" class="font-primary text-grey font-16 opacity-50 text-center">Aun no ha provisto de
  opciones a está actividad</h3>
