import { AuthService } from 'src/app/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from 'src/app/services/users/users.service';
import { ToastService } from './../../../utils/toast.service';
import { Select, Store } from '@ngxs/store';
import { PageService } from './../../../services/page.service';
import { Chapter } from './../../../models/chapter.models';
import { Component, OnInit } from '@angular/core';
import { IPage, ISeason } from 'src/app/models/page.models';
import { SetThree } from 'src/app/store/three/three.actions';
import { Observable } from 'rxjs';
import { ThreeState } from 'src/app/store/three/three.state';
import { MetadataService } from 'src/app/services/metadata/metadata.services';

@Component({
  selector: 'app-season-page',
  templateUrl: './season-page.component.html',
  styleUrls: ['./season-page.component.scss']
})
export class SeasonPageComponent implements OnInit {
  @Select(ThreeState) three$?: Observable<any>;
  chapters: Array<Chapter> = []
  inProcess: boolean = false
  season: any = null
  three: Array<ISeason> = []
  isSubscribed: boolean = false

  constructor(
    private meta: MetadataService,
    private pageService: PageService,
    private store: Store,
    private toastService: ToastService,
    private userService: UserService,
    private cookieService: CookieService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getPagesThree()
  }

  getPagesThree() {
    this.inProcess = true
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    this.pageService.getPages().subscribe({
      next: (data: any) => {
        this.toastService.closeLoader();
        this.inProcess = false
        if (data.length) {
          this.three = data as Array<ISeason>
          this.season = this.three[0]

          this.getUserData()

        }
      },
      error: (error) => {
        console.debug('entre aqui2')
        this.inProcess = false
        this.toastService.closeLoader();
        console.debug('error', error)
        this.toastService.showNotificationModal(
          '¡Ha ocurrido un error!',
          'Pongase en contacto con el administrador.',
          'notification',
          'Entendido'
        )
      }
    })
  }

  private getUserData() {
    let token = this.cookieService.get("gr_access");
    let decode_token = this.authService.getDecodedAccessToken(token);
    this.userService.getUserProfileData(decode_token.user_id).subscribe({
      next: (value) => {
        console.debug('getUserData', value)
        this.isSubscribed = value.is_subscribed

        if (this.season.pages.length) {
          this.season.pages.forEach((chapter: IPage, idx: number) => {
            chapter._enabled = false
            if (this.isSubscribed) {
              if (idx === 0) { //FIRST
                chapter._enabled = true
              } else {
                chapter._enabled = this.season.pages[idx - 1].progress.progress_percentage === 100
              }
            } else {
              // TODO: IMPROVE FLEXIBILITY BY is_preliminar_limit
              chapter._enabled = idx === 0
            }
          })
        }
      },
      error: (error) => { console.debug(error) },
    })
  }
}
