import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Component, OnInit, OnChanges } from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ItemState } from './../../../store/item.state';

@Component({
  selector: 'app-subthemes-page',
  templateUrl: './subthemes-page.component.html',
  styleUrls: ['./subthemes-page.component.scss']
})
export class SubthemesPageComponent implements OnInit, OnChanges {
  @Select(ItemState.value) value$!: Observable<number>;

  currentSeason!: string
  currentChapter!: string
  currentTheme!: string
  currentSubtheme!: string

  constructor(private router: Router) { }

  ngOnInit(): void {
    let { seasonId, chapterId, themeId, subthemeId } = this.collectRouteParams()
    this.currentSeason = seasonId
    this.currentChapter = chapterId
    this.currentTheme = themeId
    this.currentSubtheme = subthemeId
  }

  ngOnChanges() {
    let { seasonId, chapterId, themeId, subthemeId } = this.collectRouteParams()
    this.currentSeason = seasonId
    this.currentChapter = chapterId
    this.currentTheme = themeId
    this.currentSubtheme = subthemeId
  }

  collectRouteParams(): Record<string, string> {
    let params: Record<string, string> = {};

    const stack: ActivatedRouteSnapshot[] = [
      this.router.routerState.snapshot.root,
    ];

    while (stack.length > 0) {
      const route = stack.pop();
      if (route === undefined) {
        continue;
      }

      params = { ...params, ...route.params };

      stack.push(...route.children);
    }

    return params;
  }

}
