<main class="terms-page page">
  <section>
    <app-card padding="40px 60px" borderRadius="10px">
      <article card__content>
        <div class="row mb-4">
          <div class="col-12 ">
            <h2 class="font-24 font-primary--b ">
              Términos y condiciones
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 font-16 ">
            <p>
              Lorem ipsum dolor <span class="font-primary--b">sit amet consectetur adipisicing elit.</span> Porro
              voluptas nihil quasi ullam assumur
              adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur
              fugit inventore.
              quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore amet consectetur adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem
              enda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore amet consectetdicta
              fugit inventore amet consectetur adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta.
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere laudantium iste at adipisci sit,
              assumenda excepturi pariatur expedita quos esse vel optio ab perferendis doloribus quidem ea animi quis
              accusamus.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore. Amet consectetur adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore.
            </p>
            <p>
              <span class="font-primary--b">Lorem ipsum dolor sit amet consectetur adipisicing elit.</span> Porro
              voluptas nihil quasi ullam
              assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore. Amet consectetur adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore.
            </p>
            <ul>
              <li>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos natus eum blanditiis impedit
                  iste, libero perspiciatis accusantium, est voluptas similique sed magnam ad consequatur suscipit!
                  Accusantium, illo! Velit, atque recusandae!</p>
              </li>
              <li>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos natus eum blanditiis impedit
                  iste, libero perspiciatis accusantium, est voluptas similique sed magnam ad consequatur suscipit!
                  Accusantium, illo! Velit, atque recusandae!</p>
              </li>
              <li>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos natus eum blanditiis impedit
                  iste, libero perspiciatis accusantium, est voluptas similique sed magnam ad consequatur suscipit!
                  Accusantium, illo! Velit, atque recusandae!</p>
              </li>
            </ul>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptas nihil quasi ullam assumur
              adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur
              fugit inventore.
              quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore amet consectetur adipisicing elit. Porro voluptas nihil quasi ullam assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem
              enda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta
              fugit inventore amet consectetdicta
              fugit inventore amet consectetur adipisicing elit. <span class="font-primary--b">Porro voluptas nihil
                quasi</span> ullam assumenda
              dolores dolor error mollitia consectetur quaerat omnis aliquid, repellendus, quo impedit quas autem dicta.
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere laudantium iste at adipisci sit,
              assumenda excepturi pariatur expedita quos esse vel optio ab perferendis doloribus quidem ea animi quis
              accusamus.
            </p>

          </div>
        </div>
      </article>
    </app-card>
  </section>
</main>