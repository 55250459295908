import { environment } from './../environments/environment';
import { User, INewUser } from './models/user.models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { CanActivate, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    public router: Router) { }

  canActivate(): boolean {
    let canActivate = true
    if (!this.isAuthenticated()) {
      this.router.navigate(['']);
      canActivate = false
    }
    return canActivate;
  }

  public isAuthenticated(): boolean {
    const token = this.cookieService.get('gr_access');
    return !!token
  }

  signIn(user: any): Observable<any> {
    const url = environment.apiUrl + '/authentication/login'
    return this.httpClient.post(url, user).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  logout(refreshToken: { refresh: string }): Observable<any> {
    const url = environment.apiUrl + '/authentication/logout'
    const options = {
      headers: {
        Authorization: 'Bearer ' + this.cookieService.get('gr_access')
      }
    }
    return this.httpClient.post(url, refreshToken, options).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  signUp(user: INewUser): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/authentication/sign-up`, user,).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  verifyEmail(token: string, emailIdB64: string, userIdB64: string) {
    const URL = `${environment.apiUrl}/authentication/verification-email/${token}/${emailIdB64}/${userIdB64}`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.put(URL, {}).pipe(handleErrors)
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getMe(userId: number): Observable<any> {
    const URL = `${environment.apiUrl}/users/${userId}/`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.get(URL).pipe(handleErrors)
  }

  async resetPassword(token: string, password: string): Promise<{ data: any, error: boolean }> { // TODO handle errors
    const URL = environment.apiUrl + '/authentication/reset-password/confirm/';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, password })
    }

    const res = await fetch(URL, options)
    const data = await res.json()

    return { data, error: !res.ok }
  }

  async sendLinkToResetPassword(email: string): Promise<{ data: any, error: boolean }> { // TODO handle errors
    const URL = environment.apiUrl + '/authentication/reset-password/';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    }

    const res = await fetch(URL, options)
    const data = await res.json()

    return { data, error: !res.ok }
  }
}
