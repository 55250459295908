import { Component, Input, OnInit } from '@angular/core';
import { ChartService } from 'src/app/services/charts/charts.service';
import { Chart, registerables } from 'chart.js';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
Chart.register(...registerables);

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnInit {

  @Input('inDashboard') isInDashboard: any;
  @Input('chartId') chartId: any;
  @Input('chartCategory') chartCategory: any;

  public dataSets: any;
  public chart: any;
  public labels: any;
  public colors: any;

  public default: boolean = true;

  constructor(
    private chartService: ChartService,
    private meta: MetadataService,
  ) { }

  ngOnInit(): void {
    this.getChartData();
  }

  getChartData() {
    this.dataSets = this.chartService.getChartDataByCategory(this.chartCategory);
    this.labels = this.meta.getChartLabels(this.chartCategory);
    this.colors = this.meta.getChartColors(this.chartCategory);
    setTimeout(() => {
      this.initChart();
    }, 100);
  }

  initChart() {
    var canvas: any = document.getElementById(this.chartId);
    var ctx2 = canvas.getContext("2d");

    this.dataSets.forEach((data: any) => {
      this.default = data === 0
    });


    this.chart = new Chart(ctx2, {
      type: 'radar',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.dataSets,
            fill: true,
            backgroundColor: this.colors.backgroundColor,
            borderColor: this.colors.borderColor,
            pointBackgroundColor: this.colors.pointBackgroundColor,
            pointBorderColor: this.colors.pointBorderColor,
            pointHoverBackgroundColor: this.colors.pointHoverBackgroundColor,
            pointHoverBorderColor: this.colors.pointHoverBorderColor,
            pointRadius: 4,
          }
        ]
      },

      options: {
        responsive: false,
        maintainAspectRatio: false,
        // aspectRatio: 1,
        scales: {
          r: {
            // min: 0,
            // max: 3,
            ticks: {
              display: false,
            },
            angleLines: {
              color: '#a2a2a2'
            },
            grid: {
              color: '#70707042',
              borderDash: [6, 6],
            },
            pointLabels: {
              color: '#666',
              font: {
                size: 14,
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      },
    });
  }

}
