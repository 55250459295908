import { ThreeState } from './store/three/three.state';
import { ActivityPreviewComponent } from './components/core/activities/activity-preview/activity-preview.component';
import { RegularValorationActivityComponent } from './components/core/activities/regular-valoration-activity/regular-valoration-activity.component';
import { PercentualValorationActivityComponent } from './components/core/activities/percentual-valoration-activity/percentual-valoration-activity.component';
import { MultipleSelectionActivityComponent } from './components/core/activities/multiple-selection-activity/multiple-selection-activity.component';
import { ListActivityComponent } from './components/core/activities/list-activity/list-activity.component';
import { VideoActivityComponent } from './components/core/activities/video-activity/video-activity.component';
import { TextActivityComponent } from './components/core/activities/text-activity/text-activity.component';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';
import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_LANGUAGE, RecaptchaFormsModule } from "ng-recaptcha";

import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { DashboardLayoutComponent } from './components/layouts/dashboard-layout/dashboard-layout.component';
import { SignupPageComponent } from './components/pages/signup-page/signup-page.component';
import { SigninPageComponent } from './components/pages/signin-page/signin-page.component';
import { RecoveryPageComponent } from './components/pages/recovery-page/recovery-page.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { SeasonsPageComponent } from './components/pages/seasons-page/seasons-page.component';
import { ProgressPageComponent } from './components/pages/progress-page/progress-page.component';
import { ProfilePageComponent } from './components/pages/profile-page/profile-page.component';
import { VcoachPageComponent } from './components/pages/vcoach-page/vcoach-page.component';
import { MembershipPageComponent } from './components/pages/membership-page/membership-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { DrawerComponent } from './components/core/drawer/drawer.component';
import { CardComponent } from './components/core/card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingComponent } from './components/core/loading/loading.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent, ModalContent } from './components/core/modal/modal.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { OnboardingStepperComponent } from './components/core/onboarding-stepper/onboarding-stepper.component';
import { VideoComponent } from './components/core/video/video.component';
import { EmailVerificationPageComponent } from './components/pages/email-verification-page/email-verification-page.component';
import { SeasonsListComponent } from './components/core/seasons-list/seasons-list.component';
import { PanelComponent } from './components/core/panel/panel.component';
import { ChaptersListComponent } from './components/core/chapters-list/chapters-list.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChapterPageComponent } from './components/pages/chapter-page/chapter-page.component';
import { SeasonPageComponent } from './components/pages/season-page/season-page.component';
import { PlaylistComponent } from './components/core/playlist/playlist.component';
import { ActivitiesComponent } from './components/core/activities/activities.component';
import { RadioComponent } from './components/core/radio/radio.component';
import { SubthemesPageComponent } from './components/pages/subthemes-page/subthemes-page.component';
import { NgxsModule } from '@ngxs/store';
import { ItemState } from './store/item.state';
import { ErrorLayoutComponent } from './components/layouts/error-layout/error-layout.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { VerticalStepperComponent } from './components/core/vertical-stepper/vertical-stepper.component';
import { GraphComponent } from './components/core/graph/graph.component';
import { HeaderComponent } from './components/core/header/header.component';
import { DoughnutChartComponent } from './components/core/charts/doughnut-chart/doughnut-chart.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { UserService } from './services/users/users.service';
import { MetadataService } from './services/metadata/metadata.services';
import { PersonalDataComponent } from './components/core/user/personal-data/personal-data/personal-data.component';
import { UserSecurityDataComponent } from './components/core/user/user-security-data/user-security-data/user-security-data.component';
import { UserPreferencesComponent } from './components/core/user/user-preferences/user-preferences/user-preferences.component';
import { UserSubscriptionsComponent } from './components/core/user/user-subscriptions/user-subscriptions/user-subscriptions.component';
import { UserPaymentMehodsComponent } from './components/core/user/user-payment-methods/user-payment-mehods/user-payment-mehods.component';
import { UserPaymentsHistoryComponent } from './components/core/user/user-payments-history/user-payments-history/user-payments-history.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormService } from './services/form.service';
import { LoaderComponent } from './components/core/loader/loader/loader.component';
import { UserEmailChangeComponent } from './components/core/user/user-email-change/user-email-change/user-email-change.component';
import { CommonProvider } from './services/common/common.provider';
import { ResetPasswordPageComponent } from './components/pages/reset-password-page/password-reset-page.component';
import { NewPaymentMethodComponent } from './components/core/new-payment-method/new-payment-method/new-payment-method.component';
import { PaymentService } from './services/payments/payment.service';
import { CustomDatePipe, CustomDatePipeShort } from './pipes/formatDate.pipe';
import { EmptyPageComponent } from './components/layouts/empty-page/empty-page/empty-page.component';
import { InvoicePreviewComponent } from './components/core/invoice-preview/invoice-preview/invoice-preview.component';
import { RadarChartComponent } from './components/core/charts/radar-chart/radar-chart/radar-chart.component';
import { PolarChartComponent } from './components/core/charts/polar-chart/polar-chart/polar-chart.component';
import { BarChartComponent } from './components/core/charts/bar-chart/bar-chart/bar-chart.component';
import { ProgressChartsComponent } from './components/core/progress-charts/progress-charts/progress-charts.component';
import { PercentByPipe } from './pipes/percent-by.pipe';
import { ChapterDetailResumeComponent } from './components/core/chapter-detail-resume/chapter-detail-resume.component';
import { ChaptersCarouselComponent } from './components/core/chapters-carousel/chapters-carousel.component';
import { ProgressResumeComponent } from './components/core/progress-resume/progress-resume.component';
import { MembershipCardComponent } from './components/core/membership-card/membership-card.component';
import { FinishedChapterComponent } from './components/pages/finished-chapter/finished-chapter.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    DashboardLayoutComponent,
    SignupPageComponent,
    SigninPageComponent,
    RecoveryPageComponent,
    HomePageComponent,
    SeasonsPageComponent,
    ProgressPageComponent,
    ProfilePageComponent,
    VcoachPageComponent,
    MembershipPageComponent,
    CheckoutPageComponent,
    DrawerComponent,
    CardComponent,
    LoadingComponent,
    ModalComponent,
    OnboardingStepperComponent,
    ModalContent,
    VideoComponent,
    EmailVerificationPageComponent,
    SeasonsListComponent,
    PanelComponent,
    ChaptersListComponent,
    ChapterPageComponent,
    SeasonPageComponent,
    PlaylistComponent,
    ActivitiesComponent,
    RadioComponent,
    SubthemesPageComponent,
    ErrorLayoutComponent,
    NotFoundPageComponent,
    VerticalStepperComponent,
    GraphComponent,
    HeaderComponent,
    DoughnutChartComponent,

    // Activities
    ActivityPreviewComponent,
    TextActivityComponent,
    VideoActivityComponent,
    ListActivityComponent,
    MultipleSelectionActivityComponent,
    PercentualValorationActivityComponent,
    RegularValorationActivityComponent,
    ProgressResumeComponent,
    ChapterDetailResumeComponent,
    ChaptersCarouselComponent,

    // DANIEL
    PersonalDataComponent,
    UserSecurityDataComponent,
    UserPreferencesComponent,
    UserSubscriptionsComponent,
    UserPaymentMehodsComponent,
    UserPaymentsHistoryComponent,

    // Loader
    LoaderComponent,
    UserEmailChangeComponent,
    ResetPasswordPageComponent,
    NewPaymentMethodComponent,

    //Pipes
    CustomDatePipe,
    CustomDatePipeShort,
    PercentByPipe,

    EmptyPageComponent,
    InvoicePreviewComponent,
    RadarChartComponent,
    PolarChartComponent,
    BarChartComponent,
    ProgressChartsComponent,
    MembershipCardComponent,
    FinishedChapterComponent,
    SafeHtmlPipe,
    TermsPageComponent,
  ],
  imports: [
    CarouselModule,
    CommonModule,
    BrowserModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    // RecaptchaV3Module,
    // RecaptchaFormsModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot({}),
    NgxsModule.forRoot([ItemState, ThreeState], {
      developmentMode: !environment.production,
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  exports: [
    CustomDatePipe
  ],
  entryComponents: [
    LoaderComponent
  ],
  providers: [
    CookieService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    // {
    //   provide: RECAPTCHA_LANGUAGE,
    //   useValue: "es",
    // },
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: '6LdMtngaAAAAADm3dthutPIO4UBGiR6aP4nqV9t'
    // },
    NgbActiveModal,
    UserService,
    MetadataService,
    ToastrService,
    FormService,
    CommonProvider,
    PaymentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
