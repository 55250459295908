<ng-container *ngIf="type === 'onBoarding';else defaultModal">
  <div class="onboarding">
    <app-onboarding-stepper [fnAction]="fnAction"></app-onboarding-stepper>
  </div>
</ng-container>
<ng-template #defaultModal>
  <div class="modal-header" #modal [class.modal-header-center]="type == 'notification'">
    <h4 class="font-header">{{title}}</h4>
  </div>

  <div class="modal-body">
    <p class="font-regular" [class.modal-text-center]="type == 'notification'">{{content}}</p>
  </div>

  <div class="modal-footer" [class.modal-footer-center]="type == 'notification'">
    <button type="button" class="btn btn-red bg-red" (click)="activeModal.close('done')">{{ action? action : 'Ok.
      Entendido' }}</button>
  </div>
</ng-template>