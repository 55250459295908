<div class="list-charts">
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <span class="font font-16 font-primary--b text-blue">Intereses Vocacionales</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Existen diferentes ámbitos o campos a los que pertenecen innumerables profesiones o tareas profesionales.
            Algunos de
            estos intereses nos llaman más la atención o notamos que parece que se adaptan más a nuestras inquietudes o
            motivaciones</p>
        </div>
      </div>
    </h2>
    <div class="chart-container chart-flex-container" style="margin-top: -30px;">
      <app-radar-chart [chartCategory]="'vocational_interests'" [chartId]="'vocational_interests'">
      </app-radar-chart>
    </div>
  </div>
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <span class="font font-16 font-primary--b text-blue">Aptitudes Profesionales</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>El algoritmo, tras analizar tus respuestas en los diferentes pasos, elaborará tus aptitudes profesionales
            con el
            objetivo de que seas consciente cuáles de ellas están más desarrolladas en ti</p>
        </div>
      </div>
    </h2>
    <div class="chart-container full-height">
      <app-doughnut-chart [chartId]="'profesionalChart'" [classOverlay]="true" [stats]="true"
        [chartCategory]="'professional_fields'">
      </app-doughnut-chart>
    </div>
  </div>
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <span class="font font-16 font-primary--b text-blue">Personalidad</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Poseer datos acerca de tu personalidad, saber cuáles son tus puntos fuertes y aquello
            que
            puedes mejorar es un punto de partida que te colocará en una posición ventajosa frente a otras personas que
            lo
            desconocen y por tanto tu probabilidad de acertar en tus elecciones será mayor</p>
        </div>
      </div>
    </h2>
    <div class="chart-container w-70 full-height chart-flex-container">
      <app-radar-chart [chartCategory]="'personalities'" [chartId]="'personality_chart'">
      </app-radar-chart>
    </div>
  </div>
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <span class="font font-16 font-primary--b text-blue">Inteligencias Múltiples (GADNER)</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>La Teoría de las Inteligencias Múltiples habla de que cada uno de nosotros tiene algunas partes del cerebro
            más
            desarrolladas, por tanto, se podrían decir que son más inteligentes que otras personas realizando unas
            determinadas
            actividades</p>
        </div>
      </div>
    </h2>
    <div class="chart-container full-height chart-md-container">
      <app-doughnut-chart [chartId]="'multipleIntelligences'" [classOverlay]="false" [classOverlayMultiple]="true"
        [stats]="true" [chartHeight]="'300px'" [chartCategory]="'multiple_intelligences'">
      </app-doughnut-chart>
    </div>
  </div>
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <span class="font font-16 font-primary--b text-blue">Inteligencia Emocional</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Nuestro mundo emocional se pone en juego en cada cosa que hacemos o decimos, no hay nada en este mundo que
            no nos afecte
            a nivel interior, y saber entender qué está ocurriendo y qué tenemos que hacer con eso es primordial para
            nuestra propia
            felicidad</p>
        </div>
      </div>
    </h2>
    <div class="chart-container full-height chart-md-container" style="width: 84%;margin-left:8%;margin-top: -40px;">
      <app-polar-chart [chartCategory]="'intelligences'" [chartId]="'intelligences'">
      </app-polar-chart>
    </div>
  </div>
  <div class="card">
    <h2 class="d-flex justify-content-center align-items-center gap-3">
      <span class="font font-16 font-primary--b text-blue">Estilos de aprendizaje (Honey y Mumford)</span>
      <div class="info-block">
        <img class="icon-info" src="/assets/images/icons/icon-info.png" alt="">
        <div class="info-text">
          <p>Nuestro mundo emocional se pone en juego en cada cosa que hacemos o decimos, no hay nada en este mundo que
            no nos afecte
            a nivel interior, y saber entender qué está ocurriendo y qué tenemos que hacer con eso es primordial para
            nuestra propia
            felicidad</p>
        </div>
      </div>
    </h2>
    <div class="chart-container full-height" style="width: 84%;margin-left:8%;margin-top:0px;">
      <app-bar-chart [chartCategory]="'learning_styles'" [chartId]="'learning_styles'">
      </app-bar-chart>
      <div class="custom-legend">
        <div>
          <p><i class="bi bi-circle-fill font font-10 text-green"></i>&nbsp; Activo</p>
          <p><i class="bi bi-circle-fill font font-10 text-red"></i>&nbsp; Reflexivo</p>
        </div>
        <div>
          <p><i class="bi bi-circle-fill font font-10 text-orange"></i>&nbsp; Teórico</p>
          <p><i class="bi bi-circle-fill font font-10" style="color: #0058FF;"></i>&nbsp; Pragmático</p>
        </div>
      </div>
    </div>
  </div>
</div>
