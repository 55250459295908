import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { PaymentService } from 'src/app/services/payments/payment.service';
import { ToastService } from 'src/app/utils/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
  public payment_product: any = {};
  public payment_method: any = null;
  public discount_code: any = null;
  public coupons: any;
  public discount_coupon: any;
  public invalid_coupon: boolean = false;
  constructor(
    private location: Location,
    private router: Router,
    private routeActivated: ActivatedRoute,
    private paymentService: PaymentService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.routeActivated.params.subscribe((params) => {
      if (params && params['product_id']) {
        this.getPaymentProduct(params['product_id']);
      } else {
        this.getPaymentProduct(null);
      }
    });
    this.getCoupons()
  }

  getPaymentProduct(product_id: any) {
    this.paymentService.getPaymentsProducts(product_id).subscribe((response: any) => {
      this.payment_product = product_id ? response : response[0];
      this.setDefaultCosts();
    })
  }

  setDefaultCosts() {
    this.payment_product.tax = 0;
    this.payment_product.sub_total = this.payment_product.price;
    this.payment_product.discount = 0;
    this.payment_product.total_price = this.payment_product.sub_total + this.payment_product.tax;
  }

  getCoupons() {
    this.paymentService.getAllCoupons().subscribe((coupons) => {
      this.coupons = coupons;
    })
  }

  deleteCoupon() {
    this.discount_coupon = null;
    this.discount_code = '';
    this.setDefaultCosts();
  }

  setCheckoutPaymentMethod(payment_method: any) {
    this.payment_method = payment_method;
  }

  payProgram() {
    this.toastService.showLoaderModal('Espere un momento', 'Estamos procesando su pago');
    this.paymentService.subscribeProduct(this.payment_product.id, this.payment_method.id, this.discount_coupon ? this.discount_coupon.code : null).subscribe({
      complete: () => {
        this.toastService.closeLoader();
      },
      next: (value) => {
        this.toastService.closeLoader();
        this.toastService.showNotificationModal(
          'FELICIDADES',
          'Has dado el primer paso para encontrar tu verdadera orientación vocacional. Ya puedes disfrutrar de todos los beneficios del programa completo',
          'notification',
          'Continuar en Growth Road'
        );
        this.router.navigate(['dashboard/profile', 3], { replaceUrl: true });
      },
      error: (err) => {
        this.toastService.closeLoader();
        this.toastService.showNotificationModal('Oops!...Algo ha ido mal', 'Por favor verifica tus datos y vuelve a intentarlo', 'notification', 'Volver a intentar');
      },
    })
  }

  verifyCouponCode() {
    this.discount_coupon = this.coupons.find((coupon: any) => coupon.code.toLowerCase() === this.discount_code.toLowerCase());
    if (this.discount_coupon) {
      let discount_value = (this.discount_coupon.discount_percentage * (this.payment_product.price + 0.01) / 100);
      discount_value = Math.trunc(discount_value * 100) / 100
      this.payment_product.discount = discount_value;
      this.payment_product.sub_total = (this.payment_product.price - this.payment_product.discount);
      this.payment_product.total_price = this.payment_product.sub_total + this.payment_product.tax;
      this.invalid_coupon = false;
    } else {
      this.invalid_coupon = true;
    }

  }

  back(): void {
    this.location.back()
  }

}
