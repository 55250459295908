import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/users/users.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-user-security-data',
  templateUrl: './user-security-data.component.html',
  styleUrls: ['./user-security-data.component.scss']
})
export class UserSecurityDataComponent implements OnInit {

  @Input('user_id') user_id: any;

  security_data: any = {};
  show_password: boolean = false;
  show_new_password: boolean = false;
  show_confirm_password: boolean = false;
  password_match_uppercase: boolean = false;
  password_match_lowercase: boolean = false;
  password_match_number: boolean = false;
  password_match_special_chars: boolean = false;
  can_continue: boolean = false;
  in_progress: boolean = false;

  constructor(
    private toastService: ToastService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.setSecurityData();
  }

  setSecurityData() {
    this.security_data = {
      password: '',
      new_password: '',
      new_password2: ''
    };
    this.can_continue = false;
    this.password_match_uppercase = false;
    this.password_match_lowercase = false;
    this.password_match_number = false;
    this.password_match_special_chars = false;
  }

  checkForPasswordMatch(str: string) {
    let upperCaseRegExp = /[A-Z]/;
    let lowerCaseRegExp = /[a-z]/;
    let numberRegExp = /\w*\d{1,}\w*/;
    let specialCharRegExp = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.password_match_uppercase = upperCaseRegExp.test(str);
    this.password_match_lowercase = lowerCaseRegExp.test(str);
    this.password_match_number = numberRegExp.test(str);
    this.password_match_special_chars = specialCharRegExp.test(str);
    this.checkToContinue();
  }

  checkToContinue() {
    this.can_continue =
      ((this.security_data.password)
        && (this.security_data.password !== '')
        && this.password_match_uppercase
        && this.password_match_lowercase
        && (this.security_data.new_password.length > 7)
        && ((this.password_match_number) || (this.password_match_special_chars))
        && (this.security_data.new_password == this.security_data.new_password2))
  }

  changePassword() {
    this.in_progress = true;
    this.userService.changeUserPassword(this.user_id, this.security_data).subscribe((response) => {
      this.in_progress = false;
      this.setSecurityData();
      this.toastService.showNotificationModal('Datos guardados', 'La contraseña se ha actualizado correctamente', 'notification', 'Continuar en Growth Road');
    }, error => {
      this.in_progress = false;
      this.toastService.showNotificationModal('Oops!...Algo ha ido mal', 'Por favor verifica tus datos y vuelve a intentarlo', 'notification', 'Volver a intentar');
    })
  }

}
