<div class="container">
    <div class="table" *ngIf="payment_records && payment_records.length > 0">
        <table class="table">
            <thead>
              <tr>
                <th>Referencia</th>
                <th>Fecha</th>
                <th>Descripción</th>
                <th>Estatus</th>
                <th>Monto</th>
                <th>Recibo</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of payment_records">
                <td class="link">#{{ record.id }}</td>
                <td> {{ record.intent_datetime | customDatePipeShort }} </td>
                <td><p class="description">{{ record.order.subscription.product }}</p></td>
                <td 
                    class="status" 
                    [class.text-orange]="record.status == 'Pendiente'"
                    [class.text-green]="record.status == 'Aprobado'"
                    [class.text-red]="record.status == 'Rechazado'">
                    {{ record.status }}
                </td>
                <td>{{ record.order.total_amount }} €</td>
                <td>
                    <!-- <a class="btn btn-white btn-xs"><i class="bi-file-earmark-arrow-down-fill me-1"></i>Descargar PDF</a>&nbsp;&nbsp; -->
                    <a class="btn btn-white btn-xs" (click)="openInvoice(record)"><i class="bi bi-eye-fill"></i> Vista previa</a>
                </td>
              </tr>
            </tbody>
        </table>
    </div>
    <div class="table" *ngIf="payment_records && payment_records.length == 0">
        <app-empty-page></app-empty-page> 
    </div>
</div>