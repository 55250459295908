import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth.service';
import { ChartService } from 'src/app/services/charts/charts.service';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
import { PageService } from 'src/app/services/page.service';
import { UserService } from 'src/app/services/users/users.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-progress-page',
  templateUrl: './progress-page.component.html',
  styleUrls: ['./progress-page.component.scss']
})
export class ProgressPageComponent implements OnInit {
  circleProgressOption: any = {
    radius: 70,
    space: -8,
    outerStrokeGradient: false,
    outerStrokeWidth: 8,
    outerStrokeColor: "#11CEA0",
    innerStrokeColor: "#E5E5E5",
    innerStrokeWidth: 8,
    animationDuration: 1000,
    showBackground: false,
    clockwise: true,
    startFromZero: true,
    lazy: true,
    width: '500px',
    titleColor: 'rgba(55, 64, 82, .5)',
    unitsColor: 'rgba(55, 64, 82, .5)',
    unitsFontSize: '32px',
    titleFontSize: '32px',
    titleFontWeight: 'bold',
    showSubtitle: false,
  }
  chapters: any;
  show_charts: boolean = false;
  userProgress: any;
  seasonStats: any = {};
  seasonActivities: any = [];
  constructor(
    private meta: MetadataService,
    private userService: UserService,
    private authService: AuthService,
    private chartService: ChartService,
    private cookieService: CookieService,
    private toastService: ToastService,
    private pageService: PageService,
  ) { }

  ngOnInit(): void {
    //this.chapters = this.meta.getDummyChapters();
    this.getUserChartParams();
    this.getUserProgress();
  }

  getUserChartParams() {
    this.toastService.showLoaderModal('Espere un momento', 'Estamos cargando la información');
    let token = this.cookieService.get("gr_access");
    let decode_token = this.authService.getDecodedAccessToken(token);
    this.userService.getUserParameters(decode_token.user_id).subscribe((user_params: any) => {
      this.toastService.closeLoader();
      this.show_charts = true;
      this.chartService.setChartsData(user_params);
      //this.chartService.setDummyChartData();
    })
  }

  getUserProgress() {
    this.pageService.getPages().subscribe((season: any) => {
      this.userProgress = season[0].progress;
      this.chapters = season[0].pages;

      /* Remove below coment to test stats */

      // this.userProgress.progress_percentage = 85;
      // this.chapters[0].progress.progress_percentage = 100;
      // this.chapters[1].progress.progress_percentage = 75;
      // this.chapters[2].progress.progress_percentage = 45;
      // this.chapters[0].pages[0].progress.progress_percentage = 100;
      // this.chapters[0].pages[0].is_activity = true;
      // this.chapters[0].pages[1].is_activity = true;
      // this.chapters[1].pages[0].is_activity = true;
      // this.chapters[2].pages[0].is_activity = true;
      // this.chapters[0].pages[1].progress.progress_percentage = 100;
      // this.chapters[1].pages[0].progress.progress_percentage = 100;
      this.setSeasonStats();
    })
  }

  setSeasonStats() {
    // Get all themes of season
    this.seasonStats.all_themes = this.chapters.reduce((a: string | number, b: { pages: string | number | any; }) => +a + +b.pages.length, 0);

    // Calculate complete themes
    this.seasonStats.completed_themes = this.chapters.map((chapter: any) => chapter.pages.filter((page: any) => page.progress.progress_percentage == 100).length);
    this.seasonStats.completed_themes = this.seasonStats.completed_themes.reduce((a: any, b: any) => +a + +b, 0);

    //Calculate complete chapters
    this.seasonStats.completed_chapters = this.chapters.filter((chapter: any) => chapter.progress.progress_percentage == 100).length;

    //Get season activities
    this.chapters.map((chapter: any) => chapter.pages.filter((page: any) => page.is_activity ? this.seasonActivities.push(page) : ''));

    //Calculate complete activities
    this.seasonStats.completed_activities = this.seasonActivities.filter((activity: any) => activity.progress.progress_percentage == 100).length;
  }

}
