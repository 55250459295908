import { ToastService } from 'src/app/utils/toast.service';
import { Component, Input, OnInit } from '@angular/core';
import { Feature } from 'src/app/models/feature.models';
import { PaymentService } from 'src/app/services/payments/payment.service';

@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss']
})
export class UserSubscriptionsComponent implements OnInit {
  @Input('profile_data') profile_data: any;
  productPrice: string | null = null

  public user_subscriptions: any;

  features: Array<Feature> = [
    {
      title: 'Acceso a todos los capítulos de orientación vocacional de nueva generación',
      content: '(video curso)'
    },
    {
      title: 'Orientación Académica',
      content: "individualizada, realizada por nuestro algoritmo de inteligencia artificial."
    },
    {
      title: 'Reporte de Growth Road',
      content: '',
      subitems: [
        {
          title: 'Estudio vocacional del alumno(personalidad, inteligencia emocional, inteligencias múltiples, intereses vocacionales, capacidades profesionales y estilos de aprendizaje).'
        },
        {
          title: 'Estudio del perfil académico del alumno(5 opciones de grado universitario y 5 de formación profesional adaptadas al alumno)'
        },
        {
          title: 'Informe completo en pdf para descargar, imprimir y compartir.'
        }
      ]
    },
    {
      title: 'Acceso completo a Growth Road Experiences',
      content: "donde encontrarás actividades solidarias, prácticas en empresas, experiencias en universidades..."
    },
  ]
  constructor(private paymentService: PaymentService, private toastService: ToastService) { }

  ngOnInit(): void {
    this._getUserSubscritpions();
    this.getProducts()
  }

  _getUserSubscritpions() {
    this.paymentService.getSubscriptions().subscribe((response) => {
      this.user_subscriptions = response;
      if (this.user_subscriptions.length > 0) {
        this.getProgressValue();
      }
    })
  }

  getProgressValue() {
    let day_progress = this.user_subscriptions[0].product.life_period - this.user_subscriptions[0].left_days;
    let subscription_progress = (day_progress * 100) / this.user_subscriptions[0].product.life_period;
    this.user_subscriptions[0].progress = subscription_progress;
  }

  getProducts() {
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    this.paymentService.getPaymentsProducts().subscribe({
      next: (value: any) => {
        if (value.length) {
          var usd = new Intl.NumberFormat("es-SP", { style: "currency", "currency": "EUR" }).format(value[0].price);
          this.productPrice = usd
        }
        this.toastService.closeLoader();
      },
      error: (error) => {
        console.debug(error)
        this.toastService.closeLoader();
      },
    })
  }

}
