import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vcoach-page',
  templateUrl: './vcoach-page.component.html',
  styleUrls: ['./vcoach-page.component.scss']
})
export class VcoachPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
