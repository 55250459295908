import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';
import { ToastService } from 'src/app/utils/toast.service';

export class MyErrorStateMatcher { //TODO this can be better reusable
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control?.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
  resetPasswordForm!: FormGroup
  formErrors: any = null
  isLoading: boolean = false
  token: string = ''
  matcher = new MyErrorStateMatcher();

  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token') as string;
    this.resetPasswordForm = this.initForm()
  }

  async onSubmit() {
    const { password } = this.resetPasswordForm.value
    this.formErrors = null
    this.isLoading = true
    
    const { errors, totalErrors } = handleFormErrors(this.resetPasswordForm)
    
    if (totalErrors) {
      console.error('Reset Password Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }
    
    const { error } = await this.authService.resetPassword(this.token, password)

    this.resetPasswordForm.reset()
    this.isLoading = false

    if (error) {
      this.toastService.showNotificationModal('Oops!...Algo ha ido mal','Por favor verifica tus datos y vuelve a intentarlo', 'notification', 'Volver a intentar');
      return
    }

    this.router.navigate(['/signin'])
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['password'].value;
    let confirmPass = group.controls['confirmPassword'].value;

    return pass === confirmPass ? null : { notSame: true }
  }

  initForm () {
    return this.fb.group({
      password: ['', [ Validators.required, Validators.minLength(4), Validators.maxLength(20) ]],
      confirmPassword: ['', [ Validators.required, Validators.minLength(4), Validators.maxLength(20) ]],
    },
    { validator: this.checkPasswords }
    )
  }
}
