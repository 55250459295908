import { ModalComponent } from '../../core/modal/modal.component';
import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth.service';
@Component({
  selector: 'app-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss'],
  providers: [CookieService],
})
export class SigninPageComponent {
  @ViewChild(ModalComponent) modal!: ModalComponent;

  inProcess: boolean = false;
  login_data: any = {
    email!: '',
    password!: '',
  }
  show_password: boolean = false;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private authService: AuthService,
  ) { }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      if ((this.login_data.email !== '') && (this.login_data.password !== '')) {
        this.onSubmit();
      }
    }
  }

  /**
   * User login
   * @public
  */
  onSubmit() {
    if (!!this.cookieService.get('gr_access')) {
      this.cookieService.deleteAll()
    }
    this.inProcess = true
    this.authService.signIn(this.login_data).subscribe({
      complete: () => {
        this.router.navigateByUrl('/dashboard')
      },
      error: (e) => {
        this.inProcess = false
        if (e.error && e.error.hasOwnProperty('detail')) {
          this.modal.open('Ha ocurrido un error', e.error.detail)
          console.error('Ha ocurrido un error al intentar iniciar sesión', e.error.detail)
        } else {
          this.modal.open('Ha ocurrido un error', 'Pongase en contacto con el administrador')
          console.error('Ha ocurrido un error', e.error)
        }
      },
      next: (data) => {
        this.inProcess = false
        this.cookieService.set("gr_refresh", data.refresh)
        this.cookieService.set("gr_access", data.access)
      },
    })

  }

}
