import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private httpClient: HttpClient) { }

  getPages() {
    const url = environment.apiUrl + '/questionaries/'

    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
  getPageById(id: string | number) {
    const url = environment.apiUrl + '/questionaries/pages/' + id + '/'

    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
