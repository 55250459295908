<div class="modal-header modal-header-center" #modal>
  <h4 class="font-primary--b">{{ title }}</h4>
</div>

<div class=" modal-body">
  <div class="text-center">
    <div class="spinner-border text-red" role="status"></div>
  </div>
  <br>
  <p *ngIf="content" class="font-regular modal-text-center">{{content}}.</p>
</div>