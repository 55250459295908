import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-onboarding-stepper',
  templateUrl: './onboarding-stepper.component.html',
  styleUrls: ['./onboarding-stepper.component.scss']
})
export class OnboardingStepperComponent implements OnInit {
  @Input() fnAction?: () => void
  defaultStep: number = 0
  currentStep!: number
  maxSteps: number = 0

  steps: Array<{ src: string, type: string }> = [
    {
      src: 'empty by now',
      type: 'video',
    },
    {
      src: '../../../assets/images/onboarding/step-1.png',
      type: 'image',
    },
    {
      src: '../../../assets/images/onboarding/step-2.png',
      type: 'image',
    },
    {
      src: '../../../assets/images/onboarding/step-3.png',
      type: 'image',
    },
    {
      src: '../../../assets/images/onboarding/step-4.png',
      type: 'image',
    },
    {
      src: '../../../assets/images/onboarding/step-5.png',
      type: 'image',
    },
    {
      src: '../../../assets/images/onboarding/step-6.png',
      type: 'image',
    },
  ]

  constructor(public activeModal: NgbActiveModal,) {
    this.currentStep = this.defaultStep
    this.maxSteps = this.steps.length - 1 //corrigiendo notacion de array
  }

  ngOnInit(): void {
  }

  nextStep() {
    if (this.currentStep < this.maxSteps) {
      this.currentStep = this.currentStep + 1
    } else {
      this.skipSteps()
    }
  }
  prevStep() {
    this.currentStep = this.currentStep > 1 ? this.currentStep - 1 : this.defaultStep
  }
  skipSteps() {
    if (this.fnAction) {
      this.activeModal.close();
      this.fnAction();
    } else {
      this.activeModal.close('success');
    }
  }

}
