<div class="panel panel-chapter" [class.carousel-panel]="isCarousel"
  [style]="{background: chapter._enabled ? chapter.background_color : '#a2a2a2'}">
  <div class="content-wrapper" [ngStyle]="{backgroundImage: 'url('+apiUrl + chapter.image+')' }">
    <div class="header">
      <h2 class="title" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">Capítulo {{chapterNumber+1}}
      </h2>
      <h3 class="sub-title" [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">{{chapter.name}}</h3>
    </div>

    <a class="progress-wrapper" [routerLink]="chapter._enabled ? '/dashboard/seasons/1/chapters/'+ chapter.id : null">
      <circle-progress *ngIf="chapter._enabled ;else locked" [outerStrokeGradient]="false"
        [outerStrokeColor]="chapter._enabled ? chapter.font_color : '#fff'"
        [percent]="chapter.progress.progress_percentage" [unitsColor]="chapter._enabled ? chapter.font_color : '#fff'"
        [titleColor]="chapter._enabled ? chapter.font_color : '#fff'" [options]="circleProgressOption">
      </circle-progress>
      <ng-template #locked>
        <img class="locked-icon" src="/assets/images/icons/icon_locked.svg" alt="">
      </ng-template>
    </a>

    <!-- TODO: Allow completed and total-->
    <p class="themes-progress" *ngIf="chapter._enabled"
      [style]="{color: chapter._enabled ? chapter.font_color : '#fff'}">
      {{ completed_themes }}/{{chapter.pages.length}} temas
    </p>
  </div>
</div>
