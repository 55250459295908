// import { ActivityService } from 'src/app/services/activity.service';
import { Component, Input, OnInit } from '@angular/core';
import { Option, OptionActivity } from 'src/app/models/activities/option.models';
import { ActivityType } from 'src/app/models/activity.model';
import { IPage } from 'src/app/models/page.models';
import { ActivityService } from 'src/app/services/activity/activity.service';

@Component({
  selector: 'app-activity-preview',
  templateUrl: './activity-preview.component.html',
  styleUrls: ['./activity-preview.component.scss']
})
export class ActivityPreviewComponent implements OnInit {
  @Input() type: string | null = null
  @Input() order!: number
  @Input() statement: string = ''
  @Input() name: string = ''
  @Input() video?: string
  @Input() options: Array<OptionActivity> = []
  @Input() min: number = 0
  @Input() max: number = 0
  @Input() helperText: string = "Por Favor, seleccione una actividad."
  @Input() parent!: IPage
  @Input() grandParent!: IPage
  validTypes: Array<ActivityType> = []

  public activityResponse: any = null
  @Input() edit: any = []

  constructor(private activityService: ActivityService) {
    this.validTypes = activityService.getActivityTypes()
  }

  getSlug(code: string | null) {
    let slug: any = false
    if (code) {
      const type = this.validTypes.find(type => type.code === code || type.slug === code)
      slug = type!.slug
    }
    return slug
  }

  ngOnInit(): void {

  }

  responseHandler(response: any) {
    this.activityResponse = response
  }

}
