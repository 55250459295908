import { EventEmitter, Injectable, Output } from '@angular/core';
import {FormControl, Validators, FormGroup, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonProvider {

    @Output() emailHasChange = new EventEmitter();

    constructor() { }

    userEmailChange(email:any){
        this.emailHasChange.emit(email);
    }
}

