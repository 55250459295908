<app-card class="max-w-lg">
  <app-modal></app-modal>

  <div class="" card__header>
    <h1 class="font-header text-center">Restaurar Contraseña</h1>
  </div>

  <div class="description text-center max-w-md mt-3" card__content>
    <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm">
      <div>
        <label for="password" class="text-start w-100 font-bold">Nueva Contraseña</label>
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['password']"
        >
          {{formErrors?.['password']?.label}}
        </span>

        <input
          id="password"
          name="password"
          class="input w-100"
          type="password"
          placeholder="Introduzca su nueva contraseña"
          formControlName="password"
        />
      </div>

      <div class="mt-3">
        <label for="confirmPassword" class="text-start w-100 font-bold">Confirmar Contraseña</label>
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="resetPasswordForm.hasError('notSame')"
        >
          La contraseña no coincide
        </span>
        
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['confirmPassword']"
        >
          {{formErrors?.['confirmPassword']?.label}}
        </span>

        <input
          id="confirmPassword"
          name="confirmPassword"
          class="input w-100"
          type="password"
          placeholder="Confirme su nueva contraseña"
          formControlName="confirmPassword"
        />
      </div>

      <button class="btn font-btn text-white bg-red w-100 font-26 mt-3">
        <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>
  
        <span class="text-white" *ngIf="!isLoading">
          Actualizar Contraseña
        </span>
      </button>
    </form>
  </div>
</app-card>
