<main class="progress-page page">
  <section *ngIf="userProgress">
    <div class="card">
      <div class="row">
        <div class="col-12 col-3 col-xxl-3 col-lg-12 col-xl-12 col-md-12 col-xs-12 col-sm-12 progress-col">
          <h2 class="font-21 font-primary--b mr-3">
            Mi progreso
          </h2>
          <div class="card-circle-progress">
            <circle-progress class="circle" [percent]="userProgress.progress_percentage"
              [options]="circleProgressOption">
            </circle-progress>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xs-12 col-sm-12 col-xxl-4 col-xl-6 col-lg-6 progress-col">
          <div *ngFor="let chapter of chapters;let i = index">
            <div *ngIf="i < 6">
              <h2 class="font-16 font-primary"><span>Capitulo {{ i+1 }}: {{ chapter.name }}</span>
                <div class="progress">
                  <div class="progress-bar" role="progressbar"
                    [style.background-color]="chapter.progress.progress_percentage > 60 ? '#00CAA8' : '#FF9E68'"
                    [style.width]="chapter.progress.progress_percentage + '%'" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </h2>
              <hr>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-12 col-xs-12 col-sm-12 col-xxl-4 col-xl-6 col-lg-6 progress-col">
          <div *ngFor="let chapter of chapters;let i = index">
            <div *ngIf="i > 5">
              <h2 class="font-16 font-primary"><span>Capitulo {{ i+1 }}: {{ chapter.name }}</span>
                <div class="progress">
                  <div class="progress-bar" role="progressbar"
                    [style.background-color]="chapter.progress.progress_percentage > 60 ? '#00CAA8' : '#FF9E68'"
                    [style.width]="chapter.progress.progress_percentage + '%'" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </h2>
              <hr>
            </div>
          </div>

        </div>
      </div>
      <div class="row stats-sumary" *ngIf="chapters">
        <div class="col-12">
          <h2 class="font font-16 text-grey-alt">{{ seasonStats.completed_chapters }}/{{ chapters.length }} Capítulos
          </h2>
        </div>
        <div class="col-12 col-3">
          <h2 class="font font-16 text-grey-alt" style="margin-bottom: 10px;">{{
            seasonStats.completed_themes}}/{{ seasonStats.all_themes }} Temas</h2>
          <h2 class="font font-16 text-grey-alt">{{ userProgress.children_total_4 }}/ {{
            userProgress.children_completed_4 }}
            Actividades</h2>
        </div>
      </div>
    </div>
  </section>
  <app-card padding="50px 25px" borderRadius="10px" boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 1px" *ngIf="!userProgress">
    <article card__content class="row">
      <div class="col-12 text-center">
        <img class="opacity-25 mb-4" src="/assets/images/icons/icon_warning.png" alt="">
        <h2 class="font-18 font-primary--b text-grey opacity-50">
          Aun no tienes progreso.
        </h2>
      </div>
    </article>
  </app-card>
  <br><br>
  <section>
    <h2 class="font-21 font-primary--b mr-3 chart-stats-title">Estadísticas &nbsp;&nbsp;
      <span class="font-18 opacity-50">Datos generados con Inteligencia Artificial</span>
    </h2>
    <br>
    <app-progress-charts *ngIf="show_charts"></app-progress-charts>
  </section>
</main>
