
<div class="modal-header modal-header-center" #modal>
    <div class="bg-filter"></div>
    <img src="../../../../../assets/images/icons/icon-brand-optimized.png">
</div>
<br><br>
<div class="text-center modal-body">
    <h3 class="font font-20 font-primary--b"> {{ content.order.name }} </h3>
    <h3 class="font font-17 font-primary text-grey-dark">Recibo de pago #{{ content.id }}</h3>
    <br><br>
    <div class="row row-cols-3">
        <div class="col" style="text-align: left;">
            <label class="text-blue font-primary--b  font-17">Monto pagado:</label>
            <h3 class="font font-17"> {{ content.order.total_amount }} € </h3>
        </div>
        <div class="col" style="text-align: left;">
            <label class="text-blue font-primary--b  font-17">Fecha:</label>
            <h3 class="font font-17">{{ content.intent_datetime | customDatePipeShort }} </h3>
        </div>
        <div class="col" style="text-align: left;">
            <label class="text-blue font-primary--b  font-17">Medio de pago:</label>
            <h3 class="font font-17 card-brand">
                <img *ngIf="content.st_credit_card.card_brand == 'mastercard'" src="../../../../../../assets/images/icons/master-card-logo.png">
                <img *ngIf="content.st_credit_card.card_brand == 'visa'" src="../../../../../../assets/images/icons/VISA-logo-color.jpeg">
                <img *ngIf="content.st_credit_card.card_brand == 'amex'" src="../../../../../../assets/images/icons/amex-logo.png">
                <img *ngIf="content.st_credit_card.card_brand == 'diners'" src="../../../../../../assets/images/icons/diners-logo.png">
                <img *ngIf="content.st_credit_card.card_brand == 'discover'" src="../../../../../../assets/images/icons/discover-logo.png">
                <img *ngIf="content.st_credit_card.card_brand == 'unionpay'" src="../../../../../../assets/images/icons/union-pay-logo.png">
                <img *ngIf="content.st_credit_card.card_brand == 'jcb'" src="../../../../../../assets/images/icons/jcb-logo.png">
                •••• {{ content.st_credit_card.card_last_four }} 
            </h3>
        </div>
    </div>
    <br>
    <h3 class="font-16 text-blue font-primary--b" style="text-align: left;"> RESUMEN DE COMPRA </h3>
    <div class="invoice-summary text-left">
        <h3 class="font font-16">
            {{ content.order.subscription.product }}
            <span> {{ content.order.product_amount | number:'1.2-2' }} € </span>
        </h3>
        <hr>
        <h3 class="font font-16">
            Descuento
            <span> {{ content.order.discount_amount | number:'1.2-2' }} € </span>
        </h3>
        <hr>
        <h3 class="font font-16">
            Impuesto
            <span> {{ content.order.tax_amount | number:'1.2-2' }} € </span>
        </h3>
        <hr>
        <h3 class="font font-16">
            Subtotal
            <span> {{ content.order.subtotal_amount | number:'1.2-2' }} € </span>
        </h3>
        <hr>
        <h3 class="font font-16">
            Total pagado
            <span class="font-18 font-primary--b"> {{ content.order.total_amount | number:'1.2-2' }} € </span>
        </h3>
        <br><hr>
        <p class="font font-14 text-grey-alt" style="text-align: center;">
            Si tienes alguna pregunta, puedes contactarnos a través del correo <span class="text-red font">example@gmail.com</span> o por nuestra línea de atención <span class="text-red font">+1 898 34-5492</span>
        </p>
    </div>
</div>