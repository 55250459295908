<main class="chapter-page page">
  <!-- Header -->
  <section class="header-wrapper">
    <button class="btn" routerLink="/dashboard/seasons/1" title="Volver al menu de capítulos">
      <img src="/assets/images/icons/icon_prev.svg" alt="">
    </button>
    <h1 class="font-35 font-primary--b text-center">Capítulo {{chapterOrder}}: {{chapterName}}</h1>
  </section>

  <!-- prev Completed -->
  <section class="content-wrapper" *ngIf="prevPage && prevPage.progress!.progress_percentage === 100 || isFirst">
    <article class="row">
      <div class="col-12">
        <div class="video-container" *ngIf="pageSelected && pageSelected.video_url">
          <app-video [options]="{
                    fluid: true,
                    aspectRatio: '16:9',
                    autoplay: false,
                    controls: true,
                    sources: [{src: pageSelected.video_url!}]
                  }">
          </app-video>
        </div>
        <h2 class="text-center font-18 font-primary--eb opacity-25 mb-4"
          *ngIf="pageSelected && !pageSelected.video_url">
          Aun no se ha cargado un video para este subtema
        </h2>
      </div>
    </article>
    <article *ngIf="pageSelected">
      <app-activities [parent]="parent!" [grandParent]="grandParent!"
        [isPreliminarLimit]="pageSelected.is_preliminar_limit" [isSubscribed]="isSubscribed"
        [isChapterEnd]="pageSelected._is_chapter_end!" [questionaryId]="questionaryId" [activities]="pageSelected.pages"
        (nextPageEvent)="nextPageHandler()" (prevPageEvent)="prevPageHandler()">
      </app-activities>
    </article>
  </section>

  <!-- prev Incompleted -->
  <section class="content-wrapper" *ngIf="prevPage && prevPage.progress!.progress_percentage < 100 && !isFirst">
    <div class="incomplete-content text-center">
      <p class=" font-24 font-primary--b text-center">No tan rápido</p>
      <img class="img-fluid icon-gif" src="/assets/images/icons/hide.gif" alt="">
      <p class="font-18 font-primary--b text-center opacity-25 mt-3">Termina las actividades anteriores para continuar.
      </p>
    </div>
  </section>

  <!-- Playlist -->
  <aside class="playlist-wrapper">
    <app-playlist [themes]="themes"></app-playlist>
  </aside>

</main>