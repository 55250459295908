import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-user-payments-history',
  templateUrl: './user-payments-history.component.html',
  styleUrls: ['./user-payments-history.component.scss']
})
export class UserPaymentsHistoryComponent implements OnInit {
  public payment_records:any = [];
  constructor(private paymentService:PaymentService, private toastService:ToastService) { }

  ngOnInit(): void {
    this.getPaymentRecords();
  }

  getPaymentRecords(){
    this.paymentService.getPaymentHistory().subscribe((payments_record:any)=>{
      this.payment_records = [...payments_record];
    })
  }

  openInvoice(record:any){
    this.toastService.showInvoiceModal(record);
  }

}
