import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-user-payment-mehods',
  templateUrl: './user-payment-mehods.component.html',
  styleUrls: ['./user-payment-mehods.component.scss']
})
export class UserPaymentMehodsComponent implements OnInit {
  @Input('profile_data') profile_data:any;
  @Input('checkout') checkout:any;
  
  @Output() paymentForCheckoutSelected = new EventEmitter();

  payment_methods:any;
  new_payment:boolean=false;

  constructor(
    private meta:MetadataService,
    private toastService:ToastService,
    private paymentService:PaymentService,
    ) { }

  ngOnInit(): void {
    this.getPaymentMethods(true);
  }

  getPaymentMethods(show_loaders?:any,new_payment?:any){
    if(show_loaders) this.toastService.showLoaderModal('Espere un momento','Estamos cargando la información');
    this.paymentService.getUserPaymentMethods().subscribe((payments:any)=>{
      if(show_loaders) this.toastService.closeLoader();
      this.payment_methods = [...payments];
      if(this.checkout && (this.payment_methods.length > 0)){
        let payment_method_to_checkout = this.payment_methods.find((payment:any)=> payment.is_default);
        if(payment_method_to_checkout && !new_payment){
          this.selectPaymentForCheckout(payment_method_to_checkout);
        }else{
          this.payment_methods[0].use_for_checkout = true;
          this.selectPaymentForCheckout(this.payment_methods[0]);
        }
      }
    })
  }

  deleteCard(payment_method:any){
    this.toastService.showLoaderModal('Espere un momento','Estamos procesando la información');
    this.paymentService.deletePaymentMethod(payment_method.id).subscribe((payments:any)=>{
      this.toastService.showNotificationModal('Medio de pago eliminado','La tarjeta ha sido eliminada correctamente','notification','Ok. Entendido');
      this.toastService.closeLoader();
      this.getPaymentMethods(false);
    })
  }

  setDefaultCard(payment_method:any){
    this.toastService.showLoaderModal('Espere un momento','Estamos procesando la información');
    this.paymentService.setDafaultPaymentMethod(payment_method.id).subscribe((payments:any)=>{
      this.payment_methods.map((card:any)=> card.id == payment_method.id ? card.is_default = true : card.is_default = false);
      this.toastService.closeLoader();
      this.toastService.showNotificationModal('Información guardada','Tu tarjeta ha sido guardada como predeterminada','notification','Ok. Entendido');
      this.getPaymentMethods(false);
    })
  }

  selectPaymentForCheckout(payment:any){
    this.payment_methods.map((payment:any)=> payment.use_for_checkout = false )
    payment.use_for_checkout = true;
    this.paymentForCheckoutSelected.emit(payment);
  }

}
