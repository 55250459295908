import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { UserService } from 'src/app/services/users/users.service';
import { CookieService } from 'ngx-cookie-service';
import { normalizeCategories } from 'src/app/utils/parameters';
import { ChartService } from 'src/app/services/charts/charts.service';

@Component({
  selector: 'app-finished-chapter',
  templateUrl: './finished-chapter.component.html',
  styleUrls: ['./finished-chapter.component.scss']
})
export class FinishedChapterComponent implements OnInit {
  userIsSuscribed = false
  userId: number = 0
  userFinishedChapter = true
  isLoading: boolean = true
  userVocationalInterestsParameters: any = null
  reportUrlToDownload: string = ''

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private userService: UserService,
    private chartService: ChartService
  ) { }

  ngOnInit(): void {
    const token = this.cookieService.get('gr_access')
    const { user_id } = this.authService.getDecodedAccessToken(token)

    this.authService.getMe(user_id).subscribe({
      error: (err) => console.error('getMe error', err),
      next: (dataGetMe: any) => {
        this.userIsSuscribed = dataGetMe.is_subscribed
        this.userId = dataGetMe.id
      },
      complete: () => {
        this.userService.getUserParameters(user_id).subscribe({
          error: (err) => console.error('getUserParameters error', err),
          next: (data: any) => {
            const categories = normalizeCategories(data)
            this.userVocationalInterestsParameters = categories.find(category => category.idString === 'vocational_interests')?.parameters

            this.chartService.setChartsData(data)
          },
          complete: () => this.isLoading = false
        })

        if (this.userIsSuscribed) {
          this.userService.getFinalReport().subscribe({
            error: err => console.error('getFinalReport error', err),
            next: (blob: Blob) => {
              const file = new Blob([blob], { type: 'application/pdf' })
              const fileURL = URL.createObjectURL(file)

              console.log('getFinalReport fileURL', fileURL)

              window.open(fileURL, '_blank')
              this.reportUrlToDownload = fileURL
            }
          })
        } else {
          this.userService.getPreliminarReport(this.userId).subscribe({
            error: err => console.error('getFinalReport error', err),
            next: (blob: Blob) => {
              const file = new Blob([blob], { type: 'application/pdf' })
              const fileURL = URL.createObjectURL(file)

              console.log('getFinalReport fileURL', fileURL)

              window.open(fileURL, '_blank')
              this.reportUrlToDownload = fileURL
            }
          })
        }

      }
    })
  }
}
