<div class="preview-component">
  <!-- HEAD -->
  <div class="header font-20 mb-4">
    <span class="bg-grey-light text-blue font-primary--b activity-badge">Actividad {{order}}</span>
  </div>

  <!-- HELPER TEXT -->
  <h3 class="font-primary text-grey font-16 opacity-50 text-center mt-2" *ngIf="!type">
    {{helperText}}
  </h3>

  <!-- PREVIEW ACTIVITY -->
  <div class="w-100" [ngSwitch]="getSlug(type)" *ngIf="type">
    <div class="w-100" *ngSwitchCase="'text'">
      <app-text-activity (dataEvent)="responseHandler($event)" [edit]="edit" [statement]="statement" [video]="video">
      </app-text-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'video'">
      <app-video-activity [statement]="statement" [video]="video">
      </app-video-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'simple-selection'">
      <app-multiple-selection-activity [edit]="edit" (dataEvent)="responseHandler($event)" [statement]="statement"
        [video]="video" [isSimple]="true" [min]="min" [max]="1" [options]="options"></app-multiple-selection-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'multiple-selection'">
      <app-multiple-selection-activity [edit]="edit" (dataEvent)="responseHandler($event)" [statement]="statement"
        [video]="video" [isSimple]="false" [min]="min" [max]="max" [options]="options">
      </app-multiple-selection-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'percentual-valoration'">
      <app-percentual-valoration-activity [edit]="edit" (dataEvent)="responseHandler($event)" [statement]="statement"
        [video]="video" [options]="options">
      </app-percentual-valoration-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'list'">
      <app-list-activity (dataEvent)="responseHandler($event)" [edit]="edit" [statement]="statement" [video]="video">
      </app-list-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'regular-valoration'">
      <app-regular-valoration-activity [edit]="edit" (dataEvent)="responseHandler($event)" [statement]="statement"
        [video]="video" [options]="options">
      </app-regular-valoration-activity>
    </div>
    <div *ngSwitchDefault class="text-center mt-3"></div>
  </div>
</div>
