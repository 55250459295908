import { Component, Input, OnInit } from '@angular/core';
import { IPage } from 'src/app/models/page.models';

@Component({
  selector: 'app-chapters-list',
  templateUrl: './chapters-list.component.html',
  styleUrls: ['./chapters-list.component.scss']
})
export class ChaptersListComponent implements OnInit {
  @Input() chapters!: Array<IPage>
  @Input() subscribed!: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
