<form class="form" [formGroup]="userProfileForm" *ngIf="profile_data && !change_email_view">
  <div class="row row-profile">
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
      <div class="input-row">
        <label>Imagen de Perfil</label>
        <div class="input-row-img">
          <img
            [src]="profile_data && profile_data.image_profile ? profile_data.image_profile : '../../../../assets/images/icons/icon-brand-optimized.png'">
          <!-- <input name="image_profile" class="input" type="text" formControlName="image_profile"> -->
          <div class="input-file-row">
            <input class="input" type="text"
              [value]="confirm_profile_image ? profile_picture_file.name : profile_data.image_profile ? profile_data.image_profile : '' "
              disabled style="padding-left: 10px;">
            <button class="btn bg-blue btn-primary" style="position:relative;">
              + Agregar
              <div class="file-container">
                <input type="file" accept="image/png, image/gif, image/jpeg" (change)="_fileSelectedChange($event)"
                  class="sd sd-file">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row row-profile">
    <div class="col-12">
      <div class="input-row">
        <label>Email <button class="btn btn-clear" (click)="change_email_view = true">Cambiar email</button></label>
        <input [value]="profile_data.email" disabled class="input" type="text">
      </div>
    </div>
  </div>
  <br>
  <div class="row row-profile">
    <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
      <div class="input-row">
        <label>Nombre(s)</label>
        <input name="first_name" class="input" type="text" formControlName="first_name">
      </div>
    </div>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
      <div class="input-row">
        <label>Apellido(s)</label>
        <input name="last_name" class="input" type="text" formControlName="last_name">
      </div>
    </div>
  </div>
  <br>
  <div class="row row-profile">
    <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
      <div class="input-row">
        <label>Teléfono</label>
        <input name="phone" class="input" type="text" formControlName="phone">
      </div>
    </div>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
      <div class="input-row">
        <label>NIF</label>
        <input name="tax_id" class="input" type="text" formControlName="tax_id">
      </div>
    </div>
  </div>
  <br>
  <div class="row row-profile">
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
      <div class="input-row">
        <label>Descripción</label>
        <textarea rows="3" name="description" maxlength="500" style="resize: none"
          formControlName="description"></textarea>
      </div>
    </div>
  </div>
  <br><br>
  <div class="row row-profile justify-content-end">
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12 justify-content-end">
      <button (click)="onFormSubmit()" class="btn btn-primary float-end"
        [disabled]="(!userProfileForm.valid || !userProfileForm.touched) && !confirm_profile_image" type="submit">
        Guardar cambios
      </button>
    </div>
  </div>
</form>

<app-user-email-change *ngIf="change_email_view" [user_id]="profile_data.id" [user_email]="profile_data.email"
  (close)="closeChangeEmail($event)">
</app-user-email-change>