import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }


  getRequestHeaders() {
    const options = {
      headers: {
        Authorization: 'Bearer ' + this.cookieService.get('gr_access')
      }
    }
    return options;
  }

  getUserProfileData(user_id: any): Observable<any> {
    let url = environment.apiUrl + '/users/' + user_id + '/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  geAlltUsers(): Observable<any> {
    let url = environment.apiUrl + '/users/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  changeUserPassword(user_id: any, security_data: any): Observable<any> {
    let url = environment.apiUrl + '/users/change-password/' + user_id;
    return this.httpClient.put(url, security_data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  editUserProfile(user_id: any, profile_data: any, profile_picture_file?: any): Observable<any> {
    let url = environment.apiUrl + '/users/profile/' + user_id;
    var formData = new FormData()

    !profile_data.tax_id && delete profile_data.tax_id

    for (var key in profile_data) {
      formData.append(key, profile_data[key]);
    }

    formData.delete('image_profile');

    if (profile_picture_file) formData.append('image_profile', profile_picture_file);

    return this.httpClient.put(url, formData).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  editUserEmail(change_data: any): Observable<any> {
    let url = environment.apiUrl + '/users/change-email';
    return this.httpClient.post(url, change_data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getUserParameters(userId: number): Observable<any> {
    const URL = `${environment.apiUrl}/users/${userId}/parameters`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.get(URL).pipe(handleErrors)
  }

  getPreliminarReport(userId: number): Observable<Blob> {
    const URL = `${environment.apiUrl}/reports/preliminar-report`
    return this.httpClient.get(URL, { responseType: 'blob' })
  }

  getFinalReport(): Observable<Blob> {
    const URL = `${environment.apiUrl}/reports/final-report/`
    return this.httpClient.get(URL, { responseType: 'blob' })
  }

  getPreliminarPreview(): Observable<any> {
    const URL = `${environment.apiUrl}/reports/preliminar-preview`
    return this.httpClient.get(URL)
  }
}
