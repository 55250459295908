import { SetState } from 'src/app/store/ui/ui.actions';
import { UserService } from 'src/app/services/users/users.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { NavigationEnd, Router } from '@angular/router';
import { sleep } from './../../../utils/fakers';
import { MenuOption } from '../../../models/menuOption.models';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnChanges, SimpleChanges, HostListener, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { UiStateModel } from 'src/app/store/ui/ui.models';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {
  @ViewChild('slider') slider!: ElementRef
  @ViewChild('drawer__options', { static: false }) drawer__options!: ElementRef
  showDrawer: boolean = true

  sliderMode: boolean = false



  menuOptions: Array<MenuOption> = [
    {
      link: '/dashboard/home',
      slug: 'inicio',
      title: 'Inicio',
      icon: `/assets/images/icons/icon_home.svg`,
    },
    {
      link: '/dashboard/seasons/',
      slug: 'temporadas',
      title: 'Temporadas',
      icon: `/assets/images/icons/icon_orientacion.svg`,
      dropDownOptions: [
        {
          title: 'Temporada 1',
          subTitle: 'Orientación Vocacional',
          link: '/platform/home/orientation/vocation',
          slug: 'orientation-t1',
        },
        {
          title: 'Temporada 2',
          subTitle: 'Orientación Profesional',
          link: '/platform/home/orientation/profesion',
          slug: 'orientation-t2',
        },
        {
          title: 'Temporada 3',
          subTitle: 'progressExperiencias',
          link: '/platform/home/orientation/experience',
          slug: 'orientation-t3',
        },
      ]
    },
    {
      link: '/dashboard/progress/',
      slug: 'progreso',
      title: 'Mi Progreso',
      icon: `/assets/images/icons/icon_estadisticas.svg`,
    },
    {
      link: '/dashboard/profile',
      slug: 'mi-perfil',
      title: 'Mi Perfil',
      icon: `/assets/images/icons/icon_usuario.svg`,
    },
    {
      link: '/dashboard/vcoach',
      slug: 'video-coach',
      title: 'VideoCoach',
      icon: `/assets/images/icons/icon_telefono.svg`,
    },
  ]
  isSubscribed: boolean = false

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    private userService: UserService,
    public store: Store
  ) {
    //this.boUrl = environment.boUrl
  }

  toggleShow() {
    this.showDrawer = !this.showDrawer
    // this.store.dispatch(new SetState(this.showDrawer))

    // const showDrawer = this.store.selectSnapshot<boolean>((state: UiStateModel) => state.showDrawer);
    // console.debug('[showDrawer]', showDrawer)

  }

  ngAfterViewInit(): void {
    this.initSlider()
    this.routeSlidablesHandler()
  }

  routeSlidablesHandler(): void {
    let data: NavigationEnd
    const slider = this.slider.nativeElement
    this.router.events.subscribe({
      next: (value) => {
        if (value instanceof NavigationEnd) {
          data = value
          this.renderer.setStyle(slider, 'opacity', 0) //! DEFAULT
          this.isValidRoute(data.url) && this.renderer.setStyle(slider, 'opacity', 1)
        }
      },
      error: (error) => {
        console.error(error)
      },
    });
  }

  isValidRoute(routeUrl: string): boolean {
    let isValid: boolean = true
    if (
      routeUrl !== '/dashboard/home' &&
      routeUrl !== '/dashboard/seasons' &&
      routeUrl !== '/dashboard/progress' &&
      routeUrl !== '/dashboard/profile' &&
      routeUrl !== '/dashboard/vcoach' &&
      !routeUrl.includes('/dashboard/seasons')
    ) {
      isValid = false
    }
    return isValid
  }



  async initSlider(): Promise<void> {
    await sleep(250)

    if (window.innerWidth < 768) {
      this.showDrawer = false
    }

    const slider = this.slider.nativeElement
    const drawer = this.drawer__options.nativeElement
    const activatedOption = drawer.querySelector('.navbar__option.active')

    if (activatedOption) {
      this.renderer.setStyle(slider, 'top', activatedOption.offsetTop + 'px')
    } else {
      this.renderer.setStyle(slider, 'opacity', 0)
    }
  }

  ngOnInit(): void {
    this.getUserData()
  }

  changeRoute(event: any) {
    const slider = this.slider.nativeElement
    const offsetTop = event.target.tagName !== 'A' ? event.target.parentElement.offsetTop : event.target.offsetTop
    this.renderer.setStyle(slider, 'top', offsetTop + 'px')
    this.renderer.setStyle(slider, 'opacity', 1)
  }

  // TODO: handle integrations with backend
  logoutHandler() {
    const refreshToken = {
      refresh: this.cookieService.get('gr_refresh')
    }
    this.authService.logout(refreshToken).subscribe({
      complete: () => {
        this.router.navigate(['/'])
      },
      next: (value) => {
        console.debug('Sesion Cerrada')
        this.cookieService.delete('gr_access', '/')
        this.cookieService.delete('gr_refresh', '/')
      },
      error: (err) => {
        console.error('Ha ocurrido un error, ', err)
      },
    })
  }

  private getUserData() {
    let token = this.cookieService.get("gr_access");
    let decode_token = this.authService.getDecodedAccessToken(token);
    this.userService.getUserProfileData(decode_token.user_id).subscribe({
      next: (value) => {
        this.isSubscribed = value.is_subscribed
      },
      error: (error) => { console.debug(error) },
    })
  }

}
