import { Pipe,PipeTransform } from "@angular/core";

const options:Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
};

const short_options:Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
};

@Pipe({
    name:'customDatePipe'
})

export class CustomDatePipe implements PipeTransform{

    public transform(input_date:Date): string{
        if (!input_date) {
            return '';
        } else {
            let {weekday, day, month, year} = new Intl.DateTimeFormat('ES',options).formatToParts(new Date(input_date)).reduce((acc, part) => {
                if (part.type != 'literal') {
                  acc[part.type] = part.value;
                }
                return acc;
              }, Object.create(null));
              return `${day} ${month}, ${year}`;
        }

    }
}
@Pipe({
    name:'customDatePipeShort'
})
export class CustomDatePipeShort implements PipeTransform{

    public transform(input_date:Date): string{
        if (!input_date) {
            return '';
        } else {
            let {weekday, day, month, year} = new Intl.DateTimeFormat('ES',short_options).formatToParts(new Date(input_date)).reduce((acc, part) => {
                if (part.type != 'literal') {
                  acc[part.type] = part.value;
                }
                return acc;
              }, Object.create(null));
              return `${day} ${month}, ${year}`;
        }

    }
}