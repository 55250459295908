import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership-page',
  templateUrl: './membership-page.component.html',
  styleUrls: ['./membership-page.component.scss']
})

export class MembershipPageComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
