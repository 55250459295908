import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { CommonProvider } from 'src/app/services/common/common.provider';
import { FormService } from 'src/app/services/form.service';
import { UserService } from 'src/app/services/users/users.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-user-email-change',
  templateUrl: './user-email-change.component.html',
  styleUrls: ['./user-email-change.component.scss']
})
export class UserEmailChangeComponent implements OnInit {
  @Input('user_email') user_email: any;
  @Input('user_id') user_id: any;

  @Output() close = new EventEmitter();

  public changeEmailForm!: FormGroup<any>;

  constructor(
    private formService: FormService,
    private userService: UserService,
    private commonProvider: CommonProvider,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.changeEmailForm = this.formService.getChangeEmailForm();
  }

  cancel() {
    this.close.emit();
  }

  changeEmail() {
    let new_user_email = this.changeEmailForm.get('new_email')?.value;
    let change_data = {
      id: this.user_id,
      new_email: new_user_email
    }
    this.toastService.showLoaderModal('Espere un momento', 'Estamos actualizando su correo electrónico');
    this.userService.editUserEmail(change_data).subscribe({
      complete: () => {
        this.toastService.closeLoader();
      },
      next: (value) => {
        this.toastService.showNotificationModal('Datos guardados', 'Su electrónico se ha actualizado correctamente', 'notification', 'Continuar en Growth Road');
        this.commonProvider.emailHasChange.emit(new_user_email);
        this.changeEmailForm.reset();
        this.close.emit(new_user_email);
      },
      error: (err) => {
        this.toastService.closeLoader();
        this.toastService.showNotificationModal('Oops!...Algo ha ido mal', 'Por favor verifica tus datos y vuelve a intentarlo', 'notification', 'Volver a intentar');
      },
    })
  }

}
