<nav class="header d-flex align-items-center justify-content-between">
  <div class="avatar-wrapper">
    <div class="avatar d-flex align-items-center">
      <img class="avatar-thumb" src="../../../../assets/images/icons/icon_avatar_default.png" alt=""
        *ngIf="user_data && !user_data.image_profile">
      <img class="avatar-thumb" *ngIf="user_data && user_data.image_profile" src="{{ user_data.image_profile }}">
      <h1 class="d-flex flex-column">
        <span class="font-30 font-primary--b primary">
          ¡Bienvenid@ de vuelta, {{ user_data ? user_data.first_name : '' }}!
        </span>
        <span class="font-15 text-grey secondary">
          Has progresado mucho esta semana. ¡sigue así!
        </span>
      </h1>
    </div>
  </div>
</nav>
