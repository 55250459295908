import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { UserService } from 'src/app/services/users/users.service';

declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss']
})
export class VerticalStepperComponent implements OnInit {
  // @ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;
  @ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;
  @Input() vocationalParameters!: {
    id: number
    technical: number
    investigator: number
    entrepreneur: number
    communicator: number
    analyst: number
  }
  @Input() reportUrlToDownload!: string
  isDisabled: boolean = false
  initialStep: number = 0
  maxStep!: number
  currentStep!: number
  showDownloadReport: boolean = false
  steps: Array<any> = [
    {
      type: 'card',
      show: true,
      finalStep: false,
      color: 'rgba(255,255,255,1)',
      content: `
        <p class="mb-0 text-grey" style="line-height: 1;">
          <span class="font-50 text-grey-dark font-secondary--eb">
            <span class="text-red">¡Felicidades!</span>
            <img class="icon mb-2" src="/assets/images/icons/icon_confetti.svg" width="42px" height="42px" />
            <br>
             Has completado
          </span>
          <br>
          tu primer capítulo.
        </p>
      `
    },
    {
      type: 'card',
      show: false,
      finalStep: false,
      color: 'rgba(255,255,255,1)',
      content: `
        <p class="mb-0 font-primary text-grey-dark font-20">
          Antes de empezar a tomar cualquier decisión respecto a qué estudios debes cursar,
          es importantísimo que sepas cuales son tus intereses profesionales.
        </p>`
    },
    {
      type: 'card',
      show: false,
      finalStep: false,
      color: 'rgba(255,255,255,1)',
      content: `
        <p class="mb-0 font-primary text-grey-dark font-20">
          Cuanto más te guste tu campo profesional, más motivado
          estarás para trabajar y más rápido aprenderás.
        </p>`
    },
    {
      type: 'panel',
      show: false,
      finalStep: false,
      color: '#DE377C',
      content: "<p class='text-white font-secondary--b font-20 mb-0'>Según nuestros cálculos el campo profesional que más se identifica con tu perfil es:</p>"
    },
    {
      type: 'graph',
      show: false,
      finalStep: false,
      color: 'rgba(255,255,255,0)',
      graphData: {
        data: [
          {
            data: [
              1,2,3,4,5
              // this.vocationalParameters.technical,
              // this.vocationalParameters.investigator,
              // this.vocationalParameters.entrepreneur,
              // this.vocationalParameters.communicator,
              // this.vocationalParameters.analyst,
            ]
          }
        ],
        type: 'radar',
        show: false,
        labels: ['Técnico', 'Investigador', 'Emprendedor', 'Comunicador', 'Analista'],
      }
    },
    {
      type: 'panel',
      show: false,
      finalStep: true,
      color: 'rgba(255,255,255,0)',
      content: `
      <h3 class="ml-2 font-49 text-grey-dark font-secondary--eb">
        Ténico
      </h3>
      <p class="font-20 text-grey-dark">
        Has obtenido una puntuación alta en el interés vocacional técnico, eso significa que le das mucho valor a la honestidad, a la franqueza y la perseverancia. Eres por tanto, una persona constante y persistente que te gusta acabar lo que empiezas y hacerlo de la mejor manera posible. Además buscas la sencillez en lo que haces y te caracterizas por ser una persona fundamentalmente práctica. Trabajas mejor solo o con pocas personas y huyes de las aglomeraciones y multitudes. Tienes una alta capacidad en tareas físicas que exigen capacidad manual, técnica y mecánica, así como espacial y matemática. Se te dan bien los aparatos y herramientas de todo tipo y eres una persona ingeniosa, persistente, ágil, activa, muy visual y físicamente coordinada. Posees una gran capacidad para reponerte a los contratiempos y resolver problemas, te gusta la estabilidad y te aportan seguridad las actividades rutinarias y estructuradas. En general eres una persona cuidadosa, rápida y constante.
      </p>
      <p class="font-20 text-grey-dark">
        Ahora que conocemos tu campo profesional vamos a indagar más para encontrar
        qué estudios superiores se ajustan a tu perfil.
      </p>
      `
    }
  ]
  preliminarPreview!: { id: number, parameter_name: string, preliminary_first_part: string, preliminary_second_part: string }

  constructor(private userService: UserService) {
    this.initStepper()
  }

  ngOnInit(): void {
    console.log('reportUrlToDownload', this.reportUrlToDownload)

    this.steps.forEach(step => {
      if (step.type === 'graph') {
        step.graphData.data[0].data = [
          this.vocationalParameters.technical,
          this.vocationalParameters.investigator,
          this.vocationalParameters.entrepreneur,
          this.vocationalParameters.communicator,
          this.vocationalParameters.analyst,
        ]
      }
    })

    this._getPreliminarPreview()
  }

  _getPreliminarPreview() {
    this.userService.getPreliminarPreview().subscribe({
      error: err => console.error('getPreliminarPreview err', err),
      next: (data: any) => {
        this.preliminarPreview = data
        this.steps[5].content = `
          <h3 class="ml-2 font-49 text-grey-dark font-secondary--eb">${data.parameter_name}</h3>
          <p class="font-20 text-grey-dark">${data.preliminary_first_part}</p>
          <p class="font-20 text-grey-dark">${data.preliminary_second_part}</p>
        `
      }
    })
  }

  initStepper(): void {
    this.maxStep = this.steps.length
    this.currentStep = this.initialStep
  }

  async clickHandler(): Promise<void> {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep += 1
      this.steps[this.currentStep].show = true
      this.showDownloadReport = this.steps[this.currentStep].finalStep
    }
  }

  downloadPdf() {
    FileSaver.saveAs(this.reportUrlToDownload, 'reporte');
  }
}
