import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth.service';
import { CommonProvider } from 'src/app/services/common/common.provider';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
import { UserService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  profile_data: any;
  menu_options: any;
  option_active: any;
  email_changed: boolean = false;
  email_verified: boolean = true;
  menu_options_icons:any;

  constructor(
    private meta: MetadataService,
    private cookieService: CookieService,
    private authService: AuthService,
    private commonProvider: CommonProvider,
    private routeActivated: ActivatedRoute,
    private userService: UserService) { }

  ngOnInit(): void {
    let token = this.cookieService.get("gr_access");
    let decode_token = this.authService.getDecodedAccessToken(token);
    this.getUserDetails(decode_token.user_id);
    this.setMenuOptions();
    this.listenToEmailChanges();
    this.routeActivated.params.subscribe((params) => {
      if (params && params['tab']) {
        this.handleMenuOptions(params['tab'])
      }
    })
  }

  getUserDetails(user_id: any) {
    this.userService.getUserProfileData(user_id).subscribe((user_data: any) => {
      this.profile_data = { ...user_data };
    })
  }

  setMenuOptions() {
    this.menu_options = this.meta.getUserProfileMenuOotions();
    this.menu_options_icons = this.menu_options.filter((option:any)=>option.type !== 'header');
    this.option_active = this.menu_options[1];
  }

  handleMenuOptions(id: number) {
    if (id !== -1) {
      this.menu_options.map((option: any) => option.id == id ? option.active = true : option.active = false)
      this.option_active = this.menu_options.find((option: any) => option.id == id)
    }
  }

  listenToEmailChanges() {
    this.commonProvider.emailHasChange.subscribe((email: any) => {
      if (email) {
        this.profile_data.email = email;
        this.email_changed = true;
        this.email_verified = false;
      }
    })
  }

}
