<button class="btn button bg-red" [class.hiddenDrawer]="!showDrawer" (click)="toggleShow()">
  <i *ngIf="!showDrawer" class="bi bi-justify text-white"></i>
  <i *ngIf="showDrawer" class="bi bi-text-indent-right text-white"></i>
</button>
<div class="navbar-wrapper bg-white h-100" [ngClass]="{'show' : showDrawer, 'hide': !showDrawer }">
  <nav class="navbar" [class.slideMode]="sliderMode" [ngClass]="{'show' : showDrawer, 'hide': !showDrawer }">
    <a class="navbar__brand" routerLink="/dashboard/home">
      <img class="icon" title="Growth Road" src="../../../../assets/images/icons/icon-brand-optimized.png">
    </a>

    <ul class="navbar__options list-unstyled" #drawer__options>
      <li class="slider" #slider></li>
      <li *ngFor="let option of menuOptions" class="navbar__option" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: !option.dropDownOptions}" [title]="'Ir a '+option.title"
        (click)="changeRoute($event)">
        <a class="icon" [routerLink]="[option.link]">
          <img src="{{option.icon}}" alt="">
        </a>
      </li>
    </ul>

    <button *ngIf="!isSubscribed; else callbackBlock" class="btn premium-btn bg-blue" type="button"
      title="Ir al programa completo" [routerLink]="['/dashboard/membership']">
      <img src="/assets/images/icons/icon_premium.svg" alt="" class="icon">
      <p class="label text-white">Acceder al programa completo</p>
    </button>
    <ng-template #callbackBlock>
      <div class="btn premium-btn bg-white" title="" [ngStyle]="{'cursor': 'auto'}">
        <img src="/assets/images/icons/icon_premium.svg" alt="" class="icon">
        <p class="label text-white">Acceder al programa completo</p>
      </div>
    </ng-template>

    <button class="btn" (click)="logoutHandler()" type="button" title="Cerrar Sesión">
      <img src="../../../../assets/images/icons/icon_logout.png" alt="Cerrar Sesión" class="icon">
    </button>
  </nav>
</div>
