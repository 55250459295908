import { ModalComponent } from './../../core/modal/modal.component';
import { sleep } from './../../../utils/fakers';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-recovery-page',
  templateUrl: './recovery-page.component.html',
  styleUrls: ['./recovery-page.component.scss']
})
export class RecoveryPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  inProcess: boolean = false
  email!: string
  emailError!: string

  constructor(
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  onEmail() {
    let longitud: number;
    longitud = this.email.length;
    if (
      !this.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) &&
      longitud != 0
    ) {
      this.emailError = 'Escribe un email válido';
    } else if (
      this.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.emailError = '';
    } else {
      this.emailError = 'Email Vacío';
    }
  }

  async onSubmit() {
    this.inProcess = true
    const { error } = await this.authService.sendLinkToResetPassword(this.email)
    this.inProcess = false
    this.email = ''

    if (error) {
      this.toastService.showNotificationModal(
        'Oops!...Algo ha ido mal',
        'No se pudo enviar el correo',
        'notification',
        'Volver a intentar'
      )
      return
    }

    this.toastService.showNotificationModal(
      'Link de Recuperación Enviado',
      'Revise su correo para recuperar su contraseña',
      'notification',
      'Entendido'
    )
  }
}

