<div class="back">
    <button class="font font-primary--b" (click)="back()"><i class="bi bi-chevron-left"></i></button>
</div>
<main class="page">
    <br>
    <div class="row">
        <div class="col col-12 col-lg-7 col-md-6 col-sm-12">
            <div class="payments-panel">
                <div class="header"> <h4>Mis medios de pago</h4></div>
                <br>
                <app-user-payment-mehods 
                    (paymentForCheckoutSelected)="setCheckoutPaymentMethod($event)" 
                    [checkout]="true">
                </app-user-payment-mehods>
            </div>
        </div>
        <div class="col col-12 col-lg-5 col-md-6 col-sm-12">
            <div class="checkout-panel">
                <div class="header"> <h4>Resumen del pago</h4></div>
                <div class="checkout-content">
                    <h5>{{ payment_product.name }} <span> {{ payment_product.price | number:'1.2-2' }} € </span></h5>
                    <small> {{ payment_product.description }} </small>
                    <hr>
                    <div class="discount">
                        <label>Aplicar código de descuento <span *ngIf="discount_coupon" class="text-red font-primary--b" (click)="deleteCoupon()">Eliminar cupón</span></label>
                        <br>
                        <div class="discount-input">
                            <input 
                                [disabled]="discount_coupon"
                                [(ngModel)]="discount_code" 
                                type="text" style="text-transform:uppercase" 
                                class="input" placeholder="Código de descuento" 
                                (ngModelChange)="invalid_coupon = false">
                            <button 
                                class="bg-blue btn font-btn text-white" 
                                [disabled]="!discount_code || !coupons || discount_coupon" (click)="verifyCouponCode()">
                                Validar
                            </button>
                        </div>
                        <p *ngIf="invalid_coupon" class="font-14 font-primary--b text-red" style="margin-top:10px">
                            El código de descuento no es válido
                        </p>
                        <p *ngIf="discount_coupon" class="font-14 font-primary--b text-blue-light" style="margin-top:10px">
                            <i class="bi bi-check-circle-fill text-blue-light"></i>  Cupon de descuento aplicado correctamente
                        </p>
                    </div>
                    <br><br>
                    <h5>Descuento {{ discount_coupon ? discount_coupon.discount_percentage : 0 }} %  
                        <span>
                            {{ discount_coupon && discount_coupon.discount_percentage > 0 ? '-' : '' }} 
                            {{ payment_product.discount | number:'1.2-2' }} € 
                        </span>
                    </h5>
                    <hr>
                    <h6>Sub Total  <span> {{ payment_product.sub_total  | number:'1.2-2' }}  € </span></h6>
                    <br>
                    <h6>Impuesto  <span> {{ payment_product.tax | number:'1.2-2' }} €</span></h6>
                    <br><br><br>
                    <hr>
                    <h3>Total <span> {{ payment_product.total_price | number:'1.2-2' }}  € </span></h3>
                    <br><br>
                    <button 
                        class="bg-red btn font-btn text-white w-100" 
                        [disabled]="!payment_method" 
                        (click)="payProgram()">
                        PAGAR PROGRAMA
                    </button>
                </div>
            </div>
        </div>
    </div>
  </main>
  