import { Option, OptionActivity } from 'src/app/models/activities/option.models';
import { generateID } from 'src/app/utils/fakers';
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-percentual-valoration-activity',
  templateUrl: './percentual-valoration-activity.component.html',
  styleUrls: ['./percentual-valoration-activity.component.scss']
})
export class PercentualValorationActivityComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() video?: string
  @Input() statement: string = ''
  @Input() options: Array<OptionActivity> = []

  @Output() dataEvent = new EventEmitter<any>();
  @Input() edit: any = []

  constructor() { }
  ngAfterContentInit(): void {
    if (this.edit.length) {
      this.edit.forEach((slide: any) => {
        this.options.forEach((option) => {
          slide.answers.forEach((answer: any) => {
            if (option.id === answer.question) {
              option.value = answer.answer
              // this.updateRange()
            }
          });
        });

      });
      this.dataHandler(this.options)
    }
  }

  ngOnInit(): void {
    this.initOptions()
  }

  ngAfterViewInit(): void {
    this.setTrackStyles()
  }

  updateRange(event: Event) {
    const target = event.target as HTMLInputElement
    const min = Number(target.min)
    const max = Number(target.max)
    const value = Number(target.value)
    target!.style.background = `linear-gradient(to right, #3AD6BA 0%, #3AD6BA ${(value - min) / (max - min) * 100}%, #E4E6EB ${(value - min) / (max - min) * 100}%, #E4E6EB 100%)`

    this.dataHandler(this.options)
  }

  setTrackStyles() {
    const sliders: NodeListOf<HTMLInputElement> = document.querySelectorAll(".valoration__input")
    sliders.forEach(slide => {
      const min = 0
      const max = 5
      const value = 0

      slide.style.background = `linear-gradient(to right, red 0%, red ${(value - min) / (max - min) * 100}%, #DEE2E6 ${(value - min) / (max - min) * 100}%, #DEE2E6 100%)`
    });
  }

  initOptions() {
    const cleanOptions: Array<OptionActivity> = []
    this.options!.forEach((option) => {
      if (!option.is_system_created) {
        cleanOptions.push({
          id: option.id,
          body: option.name!,
          question: 0,
          value: 0,
        })
      }
    })
    this.options = cleanOptions
  }

  dataHandler(value: any) {
    this.dataEvent.emit(value);
  }

}
