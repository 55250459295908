<main class="profile-page page">
  <h2 class="text-white">Mi Perfil</h2>
  <div class="horizontal-menu">
    <div *ngFor="let option of menu_options_icons" (click)="handleMenuOptions(option.id)"  [class.horizontal-option-active]="option.active"> 
      <i class="bi {{ option.icon }} text-white font font-24"></i>
    </div>
  </div>
</main>

<div class="profile-page-body">
  <div class="profile-panel left-panel">

    <div class="user-image">
      <div class="chip bg-blue-light">
        {{ profile_data ? profile_data.user_type : '' }}
      </div>
      <img
        [src]="profile_data && profile_data.image_profile ? profile_data.image_profile : '../../../../assets/images/icons/icon-brand-optimized.png'">
      <h4 class="color-blue">{{ profile_data?.first_name }} {{ profile_data?.last_name }} </h4>
      <h5>
        {{ profile_data?.email }} &nbsp;
        <i *ngIf="profile_data" class="bi bi-check-circle-fill" [class.text-green]="email_verified"
          [class.text-grey-light]="!email_verified" data-bs-toggle="tooltip" data-bs-placement="bottom"
          [title]="email_verified ? 'Email confirmado' : 'Email NO confirmado'"></i>
      </h5>
    </div>
    <br>
    <div class="profile-menu">
      <p *ngFor="let option of menu_options" (click)="handleMenuOptions(option.id)"
        [class.option-active]="option.active" [class.option-header]="option.type == 'header'">
        <i class="bi {{ option.icon }}" *ngIf="option.type !== 'header'"></i>
        {{ option.title }}
      </p>
    </div>

  </div>

  <div class="profile-panel">
    <h3>{{ option_active.title }}</h3>
    <hr>
    <app-personal-data *ngIf="option_active.id == 0 && profile_data" [profile_data]="profile_data"
      (profileDataChange)="profile_data = $event"></app-personal-data>
    <app-user-security-data *ngIf="option_active.id == 1" [user_id]="profile_data.id"></app-user-security-data>
    <app-user-preferences *ngIf="option_active.id == 2"></app-user-preferences>
    <app-user-subscriptions *ngIf="option_active.id == 3" [profile_data]="profile_data"></app-user-subscriptions>
    <app-user-payment-mehods *ngIf="option_active.id == 4"></app-user-payment-mehods>
    <app-user-payments-history *ngIf="option_active.id == 5"></app-user-payments-history>
  </div>
</div>
