<ul class="vertical-stepper" (click)="!isDisabled ? clickHandler() : false">
  <li *ngFor="let step of steps;index as i" class="step-wrapper">
    <div [ngSwitch]="step.type" class="step-content" *ngIf="step.show">
      <app-card
        [padding]="i === 0 ? '22px 80px' : '20px'"
        [dialog]="true"
        boxShadow="0px 1px 2px #00000029"
        borderRadius="14px"
        *ngSwitchCase="'card'"
      >
        <article
          [class.first]="i === 0"
          [class]="'type-'+step.type "
          card__content
          [innerHTML]="step.content"
        ></article>
      </app-card>

      <app-card
        padding="20px 35px"
        [backgroundColor]="step.color"
        borderRadius="6px"
        *ngSwitchCase="'panel'"
      >
        <article
          [class]="'type-'+step.type"
          card__content
          [innerHTML]="step.content"
        ></article>
      </app-card>

      <div class="graph-wrapper d-flex justify-content-center" *ngSwitchCase="'graph'">
        <app-radar-chart
          [chartCategory]="'vocational_interests'"
          [chartId]="'vocational_interests'">
        </app-radar-chart>
      </div>
    </div>
  </li>
  
  <div *ngIf="showDownloadReport" class="text-center" style="margin-top: -18px; padding-bottom: 22px;">
    <p class="mb-0 font-secondary--eb font-20 text-grey-dark">
      ¡Accede ahora al programa completo!
    </p>
  
    <a
      href="javascript:void(0)" 
      class="btn mt-2 w-100"
      style="display: block; background-color: #DE377C;"
      (click)="downloadPdf()"
    >
      <span class="text-white font-bold font-20">
        Descargar reporte
      </span>
    </a>
  </div>
  
  <div class="d-flex justify-content-end" *ngIf="!showDownloadReport">
    <button
      class="btn"
      style="color: #DE377C;"
    >
      Siguiente
    </button>
  </div>
</ul>