import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { AuthService } from 'src/app/auth.service';
import { SubthemesPageComponent } from './components/pages/subthemes-page/subthemes-page.component';
import { SeasonPageComponent } from './components/pages/season-page/season-page.component';
import { ChapterPageComponent } from './components/pages/chapter-page/chapter-page.component';
import { ProgressPageComponent } from './components/pages/progress-page/progress-page.component';
import { EmailVerificationPageComponent } from './components/pages/email-verification-page/email-verification-page.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { DashboardLayoutComponent } from './components/layouts/dashboard-layout/dashboard-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { RecoveryPageComponent } from './components/pages/recovery-page/recovery-page.component';
import { SigninPageComponent } from './components/pages/signin-page/signin-page.component';
import { SignupPageComponent } from './components/pages/signup-page/signup-page.component';
import { ProfilePageComponent } from './components/pages/profile-page/profile-page.component';
import { VcoachPageComponent } from './components/pages/vcoach-page/vcoach-page.component';
import { MembershipPageComponent } from './components/pages/membership-page/membership-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { ResetPasswordPageComponent } from './components/pages/reset-password-page/password-reset-page.component';
import { FinishedChapterComponent } from './components/pages/finished-chapter/finished-chapter.component';


const routes: Routes = [
  {
    path: "", component: AuthLayoutComponent,
    children: [
      { path: '', redirectTo: 'signin', pathMatch: 'full' },
      { path: "signup", component: SignupPageComponent },
      { path: "signin", component: SigninPageComponent },
      { path: "recovery", component: RecoveryPageComponent },
      { path: "password-reset/:token", component: ResetPasswordPageComponent },
      { path: "verification-email/:token/:emailIdB64/:userIdB64", component: EmailVerificationPageComponent },
    ]
  },

  {
    path: 'dashboard', component: DashboardLayoutComponent,
    canActivate: [AuthService],
    children: [
      { path: "", redirectTo: "home", pathMatch: 'full' },
      { path: "home", component: HomePageComponent },
      // component: SeasonsPageComponent
      { path: "seasons", redirectTo: "seasons/1" },
      { path: 'seasons/:seasonId', component: SeasonPageComponent },
      { path: 'seasons/:seasonId/chapters/:chapterId', component: ChapterPageComponent, },
      { path: 'seasons/:seasonId/chapters/:chapterId/theme/:themeId', component: ChapterPageComponent, },
      { path: 'seasons/:seasonId/chapters/:chapterId/theme/:themeId/subtheme/:subthemeId', component: ChapterPageComponent, },
      { path: "progress", component: ProgressPageComponent },
      { path: "profile", component: ProfilePageComponent },
      { path: "profile/:tab", component: ProfilePageComponent },
      { path: "vcoach", component: VcoachPageComponent },
      { path: "checkout", component: CheckoutPageComponent },
      { path: "finished-chapter", component: FinishedChapterComponent },
      { path: "checkout/:product_id", component: CheckoutPageComponent },
      { path: "terms-and-conditions", component: TermsPageComponent },
      {
        path: "membership", component: MembershipPageComponent,
        children: [
          { path: "checkout", component: CheckoutPageComponent },
        ]
      },
    ]
  },

  { path: 'sandbox', loadChildren: () => import('./components/sandbox/test-module/test-module-routing.module').then(m => m.TestModuleRoutingModule) },

  { path: '404', component: NotFoundPageComponent },
  { path: '**', redirectTo: '/404' }
  // {
  //   path: "error", component: ErrorLayoutComponent,
  //   children: [
  //     { path: "", redirectTo: "404", pathMatch: 'full' },
  //     { path: "404", component: NotFoundPageComponent },
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
