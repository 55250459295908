<app-card>
  <app-modal></app-modal>
  <div class="" card__header>
    <h1 class="font-header text-center">Recuperar cuenta</h1>
  </div>
  <div class="description text-center" card__content>
    <p class="font-regular text-grey description-content">
      Te enviaremos un correo con un código que tendrás que insertar a continuación.
    </p>

    <form action="" class="">
      <label class="error-message" *ngIf="emailError">{{emailError}}</label>
      <input (keyup)="onEmail()" [(ngModel)]="email" name="email" class="input w-100" type="text"
        placeholder="Introduce tu correo electrónico">
    </form>

    <div>
      <button [disabled]="inProcess" (click)="onSubmit()" type="button"
        class="btn font-btn text-white bg-red w-100 font-26">
        <span class="text-white" *ngIf="!inProcess; else loading">
          Enviar correo
        </span>
        <ng-template #loading>
          <app-loading color="rgba(255, 255, 255, 1)"></app-loading>
        </ng-template>
      </button>
    </div>

    <span class="text-grey font-regular mt-2 d-block">
      <a class="text-purple-light link" routerLink="/signin">Volver al login</a>
    </span>
  </div>
</app-card>
