import { environment } from 'src/environments/environment';
import { ThreeState } from 'src/app/store/three/three.state';
import { IPage } from 'src/app/models/page.models';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { IncrementValue, DecrementValue, ResetValue, SetValue } from './../../../store/item.actions';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationStart, Router, NavigationEnd, NavigationError, Event } from '@angular/router';
import { Theme } from './../../../models/theme.models';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ItemState } from './../../../store/item.state';
import { SetPage } from 'src/app/store/three/three.actions';


@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit {
  @Select(ThreeState) pageSelected$!: Observable<number>;
  pageSelected!: IPage;

  chapterId: number | string | null = null
  themeId: number | string | null = null
  subthemeId: number | string | null = null
  @Input() themes!: Array<IPage>

  currentSeason!: string
  currentChapter!: string

  currentRoute!: string;
  apiUrl = environment.apiUrl

  constructor(private router: Router, private store: Store,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((qp) => {
      // chapter
      this.chapterId = this.route.snapshot.params['chapterId']
      this.themeId = this.route.snapshot.params['themeId']
      this.subthemeId = this.route.snapshot.params['subthemeId']
    });
  }


  ngOnInit(): void {
    this.getPageSelected()
  }

  getPageSelected() {
    this.pageSelected$.subscribe({
      next: (value: any) => {
        this.pageSelected = value.pageSelected
        // console.debug('themes', this.themes)
      },

      error: (error) => {
        console.debug(error)
      },
    })
  }

  selectPage(page: IPage) {
    if (page.type === 'theme') {
      this.store.dispatch(new SetPage(page.pages[0]));  //?? select first subtheme
    } else {
      this.store.dispatch(new SetPage(page));
    }
  }

}
