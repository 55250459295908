<form class="form"  [formGroup]="changeEmailForm">
    <h5 class="color-blue"><b>Cambiar correo electrónico</b></h5>
    <br>
    <p>Recuerda que tu nuevo correo no estará automáticamente validado! Debes validar el nuevo correo para iniciar sesión con el. Sin embargo, aun puedes utilizar tu correo anterior para ingresar a GrowthRoad </p>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
        <div class="input-row">
            <label>Correo Actual:</label>
            <input [value]="user_email" disabled class="input" type="text">
        </div>
    </div>

    <br>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
        <div class="input-row">
            <label>Nuevo Correo:</label>
            <input name="new_email" class="input" type="text" formControlName="new_email">
        </div>
    </div>
    <br>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
        <div class="input-row">
            <label>Confirma Nuevo Correo:</label>
            <input name="confirm_new_email" class="input"  type="text" formControlName="confirm_new_email">
        </div>
    </div>
    <br>
    <!-- <div class="input-row text-error"
        *ngIf="security_data.new_password && security_data.new_password2 && (security_data.new_password2.length > 7) && (security_data.new_password2 !== security_data.new_password)">
        <div></div>
        <p class="text-red">Las contraseñas no coinciden</p>
    </div>
     -->
    <br>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12 justify-content-end row-btns">
        <button class="btn text-white" (click)="cancel()">
            Volver a mi información personal
        </button>
        &nbsp;&nbsp;&nbsp;
        <button class="btn btn-primary" [disabled]="!changeEmailForm.valid" (click)="changeEmail()">
            Guardar nuevo correo
        </button>
    </div>
</form>