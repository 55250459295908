import { generateID } from 'src/app/utils/fakers';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-activity',
  templateUrl: './list-activity.component.html',
  styleUrls: ['./list-activity.component.scss']
})
export class ListActivityComponent implements OnInit, AfterContentInit {
  @Input() video?: string
  @Input() statement: string = '¿lorem ipsum?'
  values: Array<{ id: string, title: string }> = []
  newValue: string = ''

  @Output() dataEvent = new EventEmitter<any>();
  @Input() edit: any = []

  constructor() { }
  ngAfterContentInit(): void {
    if (this.edit.length) {
      let answers = this.edit[0].answer.split(',')
      answers.forEach((answer: any) => {
        this.values.push({
          id: generateID(),
          title: answer
        })

      });
      this.dataHandler(this.values)
    }
  }

  ngOnInit(): void {
  }

  addItem() {
    const newValue = {
      id: generateID(),
      title: this.newValue
    }
    this.values.push(newValue)
    this.newValue = ''

    this.dataHandler(this.values)
  }
  removeItem(id: string) {
    const filteredValues = this.values.filter(value => value.id !== id)
    this.values = filteredValues

    this.dataHandler(this.values)
  }

  dataHandler(values: any) {
    this.dataEvent.emit(values);
  }

}
