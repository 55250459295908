import { sleep } from './../../../utils/fakers';
import { ToastService } from './../../../utils/toast.service';
import { forkJoin, Observable } from 'rxjs';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { Component, Input, OnInit, QueryList, ViewChildren, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Activity } from 'src/app/models/activity.model';
import { ActivityPreviewComponent } from './activity-preview/activity-preview.component';
import { IPage, ISeason } from 'src/app/models/page.models';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, OnChanges {
  @ViewChildren('activityPreview') activityPreview!: QueryList<ActivityPreviewComponent>

  @Output() updatedEvent = new EventEmitter();
  @Output() prevPageEvent = new EventEmitter();
  @Output() nextPageEvent = new EventEmitter();

  @Input() _activities: Array<Activity> = []
  @Input()
  set activities(value: Array<Activity>) {
    if (value) { //null check
      this._activities = value;
      this._maxSteps = value.length;
      this._step = 1;
    }
  }
  get activities(): Array<Activity> {
    return this._activities;
  }
  @Input() questionaryId: number | null = null
  @Input() isPreliminarLimit: boolean = false;
  @Input() parent!: IPage
  @Input() grandParent!: IPage
  @Input() isSubscribed?: boolean
  @Input() isChapterEnd: boolean = false

  currentStep: number = 0
  _currentStep: number = 0
  subthemeCompleted: boolean = false
  editMode: boolean = false
  inProcess: boolean = false
  errors: Array<string> = []

  // !! new implementation

  _maxSteps: number = 0
  _step: number = 1

  constructor(private activityService: ActivityService, private toastService: ToastService) {
    console.debug(this.activities)
  }

  _nextPage() {
    if (this._step < this._maxSteps) {
      this._step = this._step + 1
    } else {
      // console.debug('siguiente subtema/tema')
      this.nextPageEvent.emit()
    }
  }

  _prevPage() {
    if (this._step > 1) {
      this._step = this._step - 1
    } else {
      console.debug('anterior subtema/tema')
    }
  }

  // !! new implementation

  ngOnChanges(changes: SimpleChanges): void {
    // console.debug('changes', changes)
    this.isCompletedSubtheme()
  }

  ngOnInit(): void {
    // this.isCompletedSubtheme()
  }

  isCompletedSubtheme() {
    let activityCompleted = 0
    this.activities.forEach(activity => {
      if (activity.questions.length === 1) { // 1 Activity - 1 Question - 1 answer
        if (activity.questions[0].answers.length) {
          activityCompleted += 1
        }
      } else if (activity.questions.length > 1) { //1 Activity - n Question - nQ Answers
        if (activity.questions[0].answers.length) {
          activityCompleted += 1
        }
      }
    });
    this.subthemeCompleted = activityCompleted === this.activities.length
  }

  nextStep() {
    const maxSteps = this.activities.length - 1
    this.currentStep = this.currentStep === maxSteps ? maxSteps : this.currentStep + 1
    this._currentStep = this._currentStep + 1
    if (this._currentStep > maxSteps) {
      this.nextPageEvent.emit();
      this._currentStep = 0
      this.currentStep = 0
    }
  }

  nextPage() {
    this.nextPageEvent.emit();
  }

  prevStep() {
    const minStep = 0
    this.currentStep = this.currentStep === minStep ? minStep : this.currentStep - 1
  }

  goToStep(step: number) {
    this.currentStep = step
  }

  async save() {
    this.inProcess = true
    const activity = this.activityPreview.get(this._step - 1)
    const activityMeta = this.activities[this._step - 1]

    this.errors = []
    // VALIDATE
    if (activity?.type !== 'WATCH') {
      if (activity?.activityResponse === null) {
        console.debug('ACTIVITY ', activity.edit.length)
        this.errors.push('Está actividad es requerida')
        this.inProcess = false
        return
      }
      if (typeof activity?.activityResponse == 'object' && activity?.activityResponse.length < activity?.min) {
        this.errors.push('Debes seleccionar almenos ' + activity?.min + ' campos')
        this.inProcess = false
        return
      }
    }

    let answer: string = ''

    if (activity?.type === 'FREE_TEXT') {
      answer = activity?.activityResponse
    }

    if (activity?.type === 'WATCH') {
      answer = 'si'
    }

    if (activity?.type === 'SELECT_ANSWER') {
      answer = activity?.activityResponse[0].id
    }

    if (activity?.type === 'DISCRETE_NUMERIC_ANSWER') {
      const selected = activity?.activityResponse.find((response: any) => response.selected)
      answer = selected.id
    }

    if (activity?.type === 'SELECT_MULTIPLES_ANSWERS') {
      const selected: Array<any> = []
      activity?.activityResponse.forEach((response: any) => {
        selected.push(response.id)
      });
      answer = selected.toString()
    }

    if (activity?.type === 'VALUES_LIST') {
      const selected: Array<any> = []
      activity?.activityResponse.forEach((response: any) => {
        selected.push(response.title)
      });

      answer = selected.toString()
    }

    console.debug('[isNotLastChapter?] ', activityMeta._is_chapter_end)

    if (activity!.type === 'CONTINUOUS_NUMERIC_ANSWER') {
      if (activity?.edit.length && activity?.edit[0].answers.length) { //?? Edit
        this.toastService.showLoaderModal('Espere un momento', 'Estamos actualizando su respuesta');
        const answersToEdit: Array<any> = []
        const observables: Array<any> = [];
        activity.edit.forEach((question: any, i: number) => {
          answersToEdit.push({
            answer: activity.activityResponse[i].value as string,
            questionary: question.answers[0].questionary,
            question: question.answers[0].question
          })
        });
        answersToEdit.forEach((answer, i) => {
          observables.push(this.activityService.updateAnswer(answer, activity.edit[i].answers[0].id));
        });
        forkJoin(observables).subscribe({
          next: (data) => {
            this.toastService.closeLoader();
            console.debug('[Slide actualizado]')
            this.inProcess = false
            this._nextPage()
            if (activityMeta._is_last) {
              this.editMode = false
            }

          },
          error: (error) => {
            this.toastService.closeLoader();
            console.error('[ha ocurrido un error]', error)
            this.toastService.showNotificationModal(
              '¡Ha ocurrido un error al actualizar!',
              'Pongase en contacto con el administrador.',
              'notification',
              'Entendido'
            )
            this.inProcess = false
          },
        })

      } else { //?? Save
        console.debug('save mode')
        this.toastService.showLoaderModal('Espere un momento', 'Estamos guardando su respuesta');
        const selected: Array<any> = []
        const observables: Array<any> = [];

        activity?.activityResponse.forEach((response: any) => {
          selected.push({
            answer: response.value as string,
            questionary: this.questionaryId!,
            question: response.id
          })
        });

        selected.forEach((answer, i) => {
          observables.push(this.activityService.saveAnswer(answer));
        });

        forkJoin(observables).subscribe({
          complete: () => {
            this.toastService.closeLoader();
            console.debug('[responses created]')
            this.inProcess = false
            this._nextPage()
            if (activityMeta._is_last) {
              this.editMode = false
            }
            // this.updatedEvent.emit(false)
            // this.isCompletedSubtheme()
            // if (!activityMeta._is_chapter_end) {
            //   this.nextStep()
            // }

          },
          error: (error) => {
            this.toastService.closeLoader();
            console.error('[ha ocurrido un error]', error)
            this.toastService.showNotificationModal(
              '¡Ha ocurrido un error al guardar!',
              'Pongase en contacto con el administrador.',
              'notification',
              'Entendido'
            )
            this.inProcess = false
          },
        })
      }
    } else {
      if (activity?.edit.length) { //?? Edit
        this.toastService.showLoaderModal('Espere un momento', 'Estamos actualizando su respuesta');
        let answerId
        const answerToUpdate = {
          answer: answer as string,
          questionary: this.questionaryId!,
          question: +this.activities[this._step - 1].questions[0].id
        }
        console.debug('answerToUpdate', answerToUpdate)

        if (activity.edit.length === 1) {
          answerId = activity.edit[0].id
        } else if (activity.edit.length > 1) {
          console.debug('activity.edit.length > 1')
        }

        this.activityService.updateAnswer(answerToUpdate, answerId).subscribe({
          next: (value) => {
            this.toastService.closeLoader();
            console.debug('[Respuesta actualizada] ', value)
            this.inProcess = false
            this._nextPage()
            if (activityMeta._is_chapter_end) {
              this.toggleEditMode()
            }
            // this.updatedEvent.emit(false)
            // this.isCompletedSubtheme()
            // if (!activityMeta._is_chapter_end) {
            //   this.nextStep()
            // }
          },
          error: (error) => {
            this.toastService.closeLoader();
            console.error('[ha ocurrido un error]', error)
            this.toastService.showNotificationModal(
              '¡Ha ocurrido un error al actualizar!',
              'Pongase en contacto con el administrador.',
              'notification',
              'Entendido'
            )
            this.inProcess = false
          },
        })
      } else { //?? Save
        this.toastService.showLoaderModal('Espere un momento', 'Estamos guardando su respuesta');
        const newAnswer = {
          answer: answer as string,
          questionary: this.questionaryId!,
          question: +this.activities[this._step - 1].questions[0].id
        }
        console.debug('newAnswer', newAnswer)

        this.activityService.saveAnswer(newAnswer).subscribe({
          next: (value) => {
            this.toastService.closeLoader();
            console.debug('[Respuesta creada]', value)
            this.inProcess = false
            this._nextPage()
            // this.isCompletedSubtheme()
            if (activityMeta._is_chapter_end || activityMeta._is_last) {
              // this.toggleEditMode()
              this.editMode = false
            }
          },
          error: (error) => {
            this.toastService.closeLoader();
            console.error('[ha ocurrido un error]', error)
            this.toastService.showNotificationModal(
              '¡Ha ocurrido un error al guardar!',
              'Pongase en contacto con el administrador.',
              'notification',
              'Entendido'
            )
            this.inProcess = false
          }
        })
      }

    }
  }

  async loaderToast() {
    this.toastService.showLoaderModal('Espere un momento', 'Estamos guardando la información');
    await sleep(2000)
    this.toastService.closeLoader();
  }

  toggleEditMode() {
    this.editMode = !this.editMode
  }



}
