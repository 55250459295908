<h5 class="text-blue font-primary--b"> Nuevo medio de pago </h5>
<br>
<div class="row">
    <div class="col col-6 col-md-12 col-sm-12 col-lg-6" [class.col-lg-7]="checkout">
        <div class="flip-card" [class.flip-card-rotate]="rotate" (click)="rotate = !rotate">
            <div class="flip-card-inner" [class.flip-card-inner-rotate]="rotate">
                <div class="flip-card-front">
                    <div class="credit-card">
                        <div class="card-data">
                            <p class="card-number"> 
                                {{ newCardForm.controls['number'].value.substring(4,0) }} 
                                {{ newCardForm.controls['number'].value.substring(8,4) }} 
                                {{ newCardForm.controls['number'].value.substring(12,8) }} 
                                {{ newCardForm.controls['number'].value.substring(16,12) }}
                            </p>
                            <h6>
                                Expiración 
                                <span class="valid-label"> {{ exp_month && (exp_month > 0) ? exp_month < 10 ? display_exp_month : exp_month : '' }}/{{ exp_year && (exp_year > 0) ? exp_year.substring(4,2):'' }} </span>
                            </h6>
                            <p> {{ newCardForm.controls.name.value }}</p>
                        </div>
                        <img *ngIf="card_brand == 'master'" src="../../../../../../assets/images/icons/master-card-logo.png">
                        <img *ngIf="card_brand == 'visa'" src="../../../../../../assets/images/icons/VISA-logo.png">
                        <img *ngIf="card_brand == 'amex'" src="../../../../../../assets/images/icons/amex-logo.png">
                        <img *ngIf="card_brand == 'diners'" src="../../../../../../assets/images/icons/diners-logo.png">
                        <img *ngIf="card_brand == 'discover'" src="../../../../../../assets/images/icons/discover-logo.png">
                        <img *ngIf="card_brand == 'upay'" src="../../../../../../assets/images/icons/union-pay-logo.png">
                        <img *ngIf="card_brand == 'jcb'" src="../../../../../../assets/images/icons/jcb-logo.png">
                    </div>
                </div>
                <div class="flip-card-back">
                    <div class="credit-card credit-card-reverse">
                        <div class="magnet"></div>
                        <div class="back-content">
                            <p>Código de seguridad</p> 
                            <input class="input input-number" [value]="validation_code" type="text" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-6 col-md-12 col-sm-12 col-lg-6 col-xl-12 col-xxl-6" [class.card-inputs]="!checkout" [class.col-margin-top]="checkout" [class.col-lg-8]="checkout" >
        <form [formGroup]="newCardForm">
            <div class="input-row">
                <label>Número de la tarjeta</label>
                <input type="text" class="input input-number" 
                formControlName="number" maxlength="16" (focus)="rotate = false">
            </div>
            <div class="input-row">
                <label>Nombre en la tarjeta</label>
                <input type="text"  class="input" formControlName="name" (focus)="rotate = false">
            </div>
        </form>
        <div class="row row-validations">
            <div>
                <label class="font-primary--b" style="margin-bottom: 10px;">Expiracion</label>
                <select class="form-select"[(ngModel)]="exp_month" (ngModelChange)="handleMonthInput()" (focus)="rotate = false">
                    <option [value]="0" selected disabled hidden>Mes</option>
                    <option [class.opt-disabled]="prevent_month > month" [disabled]="prevent_month > month" *ngFor="let month of month_options" [value]="month">{{ month }}</option>
                </select>
            </div>
            <div>
                <select class="form-select" [(ngModel)]="exp_year" (ngModelChange)="handleYearInput()" name="year" (focus)="rotate = false">
                    <option [value]="0" selected disabled hidden>Año</option>
                    <option *ngFor="let year of year_options" [value]="year">{{ year }}</option>
                </select>
            </div>
            <div>
                <label class="font-primary--b" style="margin-bottom: 10px;">CVC</label>
                <input type="number" class="input input-number" (focus)="rotate = true" (blur)="rotate = false" [(ngModel)]="validation_code" (ngModelChange)="handleCVCValue()">
            </div>
        </div>
    </div>
</div>
<br><br><br>
<div class="row">
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12 row-btns" [class.justify-content-end]="!checkout" [class.justify-content-start]="checkout">
        <button class="btn text-white" (click)="cancel()">
            Volver a medios de pago
        </button>
        <button class="btn btn-primary" (click)="saveNewCard()" [disabled]="!newCardForm.valid || !exp_month || !exp_year || cvc_error">
            Guardar medio de pago
        </button>
    </div>
</div>