<app-card borderRadius="15px">
  <article card__content class="card-content">
    <div class="head">
      <h2 class="font-secondary--b font-30 text-center">
        Programa Completo <span class="text-red">Growth Road</span>
        <div class="icon d-inline-block">
          <img class="img-fluid" src="/assets/images/icons/icon_crown.svg" alt="">
        </div>
      </h2>
      <h3 class="text-center font-22 font-secondary text-grey-alt" *ngIf="productPrice">
        <span class="font-secondary--eb font-49 text-blue-dark">{{productPrice}} </span> <span
          class="text-grey-alt opacity-50">+
          IVA</span>
      </h3>
      <small class="text-center d-block text-grey-alt font-15 mb-2 opacity-50">
        Pago único
      </small>
      <small class="text-center d-block text-grey-alt font-15 mb-3 opacity-50">
        Hasta 15 días de cancelación (Un 2% de Growth Road).
      </small>
    </div>
    <hr>

    <div class="content">
      <ul class="features">
        <li class="feature" *ngFor="let feature of features">
          <span class="title font-15 font-secondary--b">
            {{ feature.title }}
          </span>
          <span class="content">
            {{ feature.content }}
          </span>
          <ul class="subfeatures" *ngIf="feature.subitems">
            <li class="subfeature" *ngFor="let subitem of feature.subitems ">
              {{ subitem.title }}
            </li>
          </ul>
        </li>

      </ul>
      <button class="bg-red btn font-btn w-100" [routerLink]="['/dashboard/checkout']">
        <span class="font-secondary--b font-20 text-white">¡Acceder ahora!</span>
      </button>

      <hr>

      <p class="text-center font-secondary font-15 text-grey">
        Se aplican todos los <a routerLink="/dashboard/terms-and-conditions"
          class="text-red text-decoration-none">Términos y
          condiciones.</a> <br> La
        duración
        total son de 9 meses /
        curso escolar.
      </p>
    </div>
  </article>
</app-card>