import { environment } from 'src/environments/environment';
import { ToastService } from './../../../utils/toast.service';
import { SetPage } from './../../../store/three/three.actions';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from './../../../services/page.service';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Activity } from 'src/app/models/activity.model';
import { ThreeState } from 'src/app/store/three/three.state';
import { Select, Store } from '@ngxs/store';
import { IPage, ISeason } from 'src/app/models/page.models';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth.service';
import { UserService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-chapter-page',
  templateUrl: './chapter-page.component.html',
  styleUrls: ['./chapter-page.component.scss']
})
export class ChapterPageComponent implements OnInit {
  chapterId: number | null = null
  themeId: number | null = null
  subthemeId: number | null = null
  questionaryId: number | null = null
  isFirst: boolean = false
  parent?: IPage
  grandParent?: IPage

  chapterName!: string
  chapterOrder!: number

  inProcess: boolean = false

  @Select(ThreeState) three$?: Observable<any>
  three: Array<ISeason> = []

  @Select(ThreeState.pageSelected) pageSelected$!: Observable<any>
  pageSelected!: IPage
  prevPage!: IPage

  themes: Array<IPage> = []
  activities!: Array<Activity> | []
  apiUrl = environment.apiUrl

  isSubscribed: boolean = false

  constructor(
    // private activityService: ActivityService,
    private pageService: PageService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private toastService: ToastService,
    private cookieService: CookieService,
    private authService: AuthService,
    private userService: UserService,
  ) {
    this.route.queryParams.subscribe((qp) => {
      this.chapterId = this.route.snapshot.params['chapterId']
      this.themeId = this.route.snapshot.params['themeId']
      this.subthemeId = this.route.snapshot.params['subthemeId']
    });
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getPagesThree()
    this.getPageSelected()
    this.getUserData()
  }

  setChapterEnd() {
    console.debug('setChapterEnd')
    const lastChapter = this.three[0].pages[this.three[0].pages.length - 1]
    const lastTheme = lastChapter.pages[lastChapter.pages.length - 1]
    const lastSubtheme = lastTheme.pages[lastTheme.pages.length - 1]
    const lastActivity = lastSubtheme.pages[lastSubtheme.pages.length - 1]
    console.debug('lastChapter', lastChapter)
    console.debug('lastTheme', lastTheme)
    console.debug('lastSubtheme', lastSubtheme)
    console.debug('lastActivity', lastActivity)

    lastChapter || alert('El cuestionario no tiene capitulos')
    lastTheme || alert('El ultimo capitulo no tiene temas')
    lastSubtheme || alert('El ultimo tema no tiene subtemas')
    lastActivity || alert('El ultimo subtema no tiene actividades')

    console.debug('lastSubtheme', lastSubtheme)
    lastSubtheme._is_chapter_end = true
    if (lastActivity) {
      lastActivity._is_chapter_end = true
    }
    this.three[0].pages.forEach((chapter: any) => {
      const lastTheme = chapter.pages[chapter.pages.length - 1]
      const lastSubtheme = lastTheme.pages[lastTheme.pages.length - 1]
      const lastActivity = lastSubtheme.pages[lastSubtheme.pages.length - 1]

      if (chapter.is_preliminar_limit) {
        lastTheme.is_preliminar_limit = true
        lastSubtheme.is_preliminar_limit = true
        lastActivity.is_preliminar_limit = true
      }

      lastActivity._is_last = true
    });
  }

  getPagesThree() {
    this.inProcess = true
    this.toastService.showLoaderModal('Por favor, Espere un momento', '');
    this.pageService.getPages().subscribe({
      next: (data) => {
        this.toastService.closeLoader();
        this.inProcess = false
        this.three = data as Array<ISeason>

        this.setChapterEnd()

        this.setTypes(this.three)

        this.setChapter(this.three)

        if (this.three.length) {
          this.questionaryId = this.three[0].id
        }

      },
      error: (error) => {
        this.inProcess = false
        console.debug('error', error)
        this.toastService.closeLoader();
        this.toastService.showNotificationModal(
          '¡Ha ocurrido un error!',
          'Pongase en contacto con el administrador.',
          'notification',
          'Entendido'
        )
      }
    })
  }

  setTypes(three: Array<ISeason>) {
    three.forEach((questionary: any) => {
      questionary.type = 'questionary'
      questionary.pages.forEach((chapter: any) => {
        chapter.type = 'chapter'
        chapter.pages.forEach((theme: any) => {
          theme.type = 'theme'
          theme.pages.forEach((subtheme: any) => {
            subtheme.type = 'subtheme'
            // theme.pages.forEach((activity: any) => {
            //   activity.type = 'activity'
            // });
          });
        });
      });
    });
  }

  setChapter(three: Array<ISeason>) {
    const queryResult = this.findInThree(three, 'page_id', +this.chapterId!)
    if (queryResult) {
      this.chapterName = queryResult.name
      this.chapterOrder = queryResult.order
      this.themes = queryResult.pages
      this.themes.forEach(theme => {
        theme._selected = false
      });

      if (this.chapterId && !this.themeId && !this.subthemeId) {
        this.store.dispatch(new SetPage(queryResult.pages[0].pages[0]))
      }
      if (this.chapterId && this.themeId && !this.subthemeId) {
        const themeQuery = this.findInThree(three, 'page_id', +this.themeId!)
        themeQuery || this.router.navigate(['/404'])
        this.store.dispatch(new SetPage(themeQuery!.pages[0]))
      }
      if (this.chapterId && this.themeId && this.subthemeId) {
        const subthemeQuery = this.findInThree(three, 'page_id', +this.subthemeId!)
        subthemeQuery || this.router.navigate(['/404'])
        this.store.dispatch(new SetPage(subthemeQuery))
      }

    } else {
      console.debug('404')
      this.router.navigate(['/404'])
    }
  }

  getPageSelected() {
    this.pageSelected$.subscribe({
      next: (value: any) => {
        this.pageSelected = value
        this.prevPageHandler()

        this.parent = value
        if (this.parent) {
          this.grandParent = this.findInThree(this.three, 'page_id', this.pageSelected.parent!)
        }

      },
      error: (error) => { console.debug(error) },
    })
  }

  findInThree(three: Array<any>, key: string, value: string | number): IPage | undefined {
    let queryResult;
    JSON.stringify(three, (_, nestedValue) => {
      if (nestedValue && nestedValue[key] === value) {
        queryResult = nestedValue;
      }
      return nestedValue;
    });
    return queryResult;
  };

  activityUpdatedHandler() {
    // // Por favor, Espere un momento
    // this.toastService.showLoaderModal('Actualizando el arbol', '');
    // this.pageService.getPages().subscribe({
    //   next: (data) => {
    //     this.toastService.closeLoader();
    //     this.inProcess = false
    //     this.three = data as Array<ISeason>

    //     this.setChapterEnd()

    //     this.setTypes(this.three)

    //     this.setChapter(this.three)

    //     if (this.three.length) {
    //       this.questionaryId = this.three[0].id
    //     }


    //   },
    //   error: (error) => {
    //     console.debug(error)
    //     this.toastService.closeLoader();
    //   },
    // })
    this.ngOnInit()
  }

  prevPageHandler() {
    if (this.pageSelected) {
      const parent = this.findInThree(this.three, 'page_id', this.pageSelected.parent!)
      const grandParent = this.findInThree(this.three, 'page_id', parent?.parent!)
      if (parent) {
        let parentIdx = parent.pages.findIndex((page) => this.pageSelected.id === page.id)
        if (parentIdx + 1 === 1) {
          let grandParentIdx = grandParent!.pages.findIndex((page) => parent.id === page.id)
          console.debug('Es el primer subtema')
          if (grandParentIdx + 1 > 1) {
            this.prevPage = grandParent!.pages[grandParentIdx - 1].pages[grandParent!.pages.length - 1]
            console.debug('no es el primer tema')
            this.isFirst = false
          } else {
            console.debug('es el primer tema')
            this.prevPage = grandParent!.pages[0].pages[0]
            this.isFirst = true
          }
        } else {
          this.isFirst = false
          this.prevPage = parent.pages[parentIdx - 1]

        }
      }
    }

  }

  nextPageHandler() {
    const parent = this.findInThree(this.three, 'page_id', this.pageSelected.parent!)
    const grandParent = this.findInThree(this.three, 'page_id', parent?.parent!)
    if (parent) {
      let parentIdx = parent.pages.findIndex((page) => this.pageSelected.id === page.id)
      if (parent.pages.length === parentIdx + 1) {
        console.debug('Ultimo subtema')
        // console.debug(parent.pages.length)
        // console.debug(parentIdx + 1)
        let grandParentIdx = grandParent!.pages.findIndex((page) => parent.id === page.id)
        if (grandParent!.pages.length === grandParentIdx + 1) {
          console.debug('Ultimo tema, redireccionar al listado de capitulos')
          // this.router.navigate(['/dashboard/seasons/1'])
          // console.debug(`/dashboard/seasons/1/chapters/${this.chapterId}/theme/${this.pageSelected.parent}/subtheme/${this.pageSelected.id}`)
          this.router.navigate([`/dashboard/seasons/1/chapters/${this.chapterId}/theme/${this.pageSelected.parent}/subtheme/${this.pageSelected.id}`])
          this.ngOnInit()
        } else {
          console.debug('Siguiente tema')
          // console.debug(grandParent!.pages[grandParentIdx + 1].pages[0])
          const nextPage = grandParent!.pages[grandParentIdx + 1].pages[0]
          // this.store.dispatch(new SetPage(nextPage))
          this.router.navigate([`/dashboard/seasons/1/chapters/${this.chapterId}/theme/${nextPage.parent}/subtheme/${nextPage.page_id}`])
        }
      } else {
        console.debug('Siguiente subtema:', parent.pages[parentIdx + 1])
        const nextPage = parent.pages[parentIdx + 1]
        this.router.navigate([`/dashboard/seasons/1/chapters/${this.chapterId}/theme/${nextPage.parent}/subtheme/${nextPage.page_id}`])
        // // this.store.dispatch(new SetPage(parent.pages[parentIdx + 1]))
      }
    }
  }

  private getUserData() {
    let token = this.cookieService.get("gr_access");
    let decode_token = this.authService.getDecodedAccessToken(token);
    this.userService.getUserProfileData(decode_token.user_id).subscribe({
      next: (value) => {
        this.isSubscribed = value.is_subscribed
      },
      error: (error) => { console.debug(error) },
    })
  }
}
