import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormService } from 'src/app/services/form.service';
import { MetadataService } from 'src/app/services/metadata/metadata.services';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-new-payment-method',
  templateUrl: './new-payment-method.component.html',
  styleUrls: ['./new-payment-method.component.scss']
})
export class NewPaymentMethodComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Output() done = new EventEmitter();
  @Output() checkoutPayment = new EventEmitter();
  @Input('checkout') checkout: any;

  public card_brand!: any;
  public date: any;
  public minDate: any;
  public newCardForm: any;
  public month_options: any;
  public year_options: any;
  public exp_month: any = 0;
  public exp_year: any = 0;
  public display_exp_month: any = 0;
  public display_exp_year: any = 0;
  public prevent_month: any = 0;
  public validation_code: any = '';
  public rotate: boolean = false;
  public cvc_error: boolean = false;
  constructor(
    private formService: FormService,
    private meta: MetadataService,
    private paymentService: PaymentService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.newCardForm = this.formService.getNewPaymentMethodForm();
    this.newCardForm.controls['number'].valueChanges.subscribe((value: string | any[]) => {
      if (value.length > 3) {
        this.card_brand = this.getCreditCardImage(value);
        this.newCardForm.controls['brand'].setValue(this.card_brand);
      } else if (value.length < 4) {
        this.card_brand = '';
      }
    });
    this.getDateOptions();
  }

  getDateOptions() {
    this.month_options = this.meta.getMonthOptions();
    this.year_options = this.meta.getYearOptions();
  }

  getCreditCardImage(value: any) {
    if (value[0] == 4) {
      return 'visa'
    } else if (value[0] == 5) {
      return 'master'
    } else if (value[0] == 3) {
      if (value[1] == 4 || value[1] == 7) {
        return 'amex'
      }
      if (value[1] == 0 || value[1] == 6 || value[1] == 8) {
        return 'diners'
      }
      if (value[1] == 5) {
        return 'jcb'
      }
    } else if (value[0] == 6) {
      if (value[1] == 2) {
        return 'upay'
      } else {
        return 'discover'
      }
    } else if (value[0] == 2 && value[1] == 2 && value[2] == 2 && value[3] == 3) {
      return 'master'
    }
    return null
  }

  handleMonthInput() {
    if (this.exp_month < 10) {
      this.display_exp_month = '0' + this.exp_month;
    }
    this.newCardForm.controls['exp_month'].setValue(this.exp_month);
  }

  handleYearInput() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if ((year == this.exp_year) && (this.exp_month < (month + 1))) {
      this.exp_month = 0;
      this.prevent_month = month;
    } else {
      this.prevent_month = 0;
    }
    this.newCardForm.controls['exp_year'].setValue(this.exp_year);
  }

  handleCVCValue() {
    let validation_code = (this.validation_code).toString();
    if (this.card_brand == 'amex' && validation_code.length == 4) {
      this.cvc_error = false;
    } else if (this.card_brand !== 'amex' && validation_code.length == 3) {
      this.cvc_error = false;
    } else {
      this.cvc_error = true;
    }
    this.newCardForm.controls['cvc'].setValue(this.validation_code);
  }

  cancel() {
    this.close.emit();
  }

  saveNewCard() {
    this.toastService.showLoaderModal('Espere un momento', 'Estamos guardando la información');
    this.paymentService.createPaymentMethod(this.newCardForm.value).subscribe({
      complete: () => {
        this.toastService.closeLoader();
      },
      next: (value) => {
        this.toastService.closeLoader();
        this.toastService.showNotificationModal('Datos guardados', 'Nuevo medio de pago agregado correctamente', 'notification', 'Continuar en Growth Road');
        this.done.emit(this.checkout ? true : null);
      },
      error: (err) => {
        this.toastService.closeLoader();
        this.toastService.showNotificationModal('Oops!...Algo ha ido mal', 'Por favor verifica tus datos y vuelve a intentarlo', 'notification', 'Volver a intentar');
      },
    })
  }

}
