<section class="component-activities">
  <!-- ACTIVITIES -->
  <div class="activity-wrapper mt-3">
    <app-card borderRadius="15px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" padding="31px 35px">
      <!-- INCOMPLETED -->
      <article card__content [hidden]="subthemeCompleted && !editMode">

        <!-- PREVIEW  -->
        <div class="activity-content">
          <div *ngFor="let activity of activities; index as idx">
            <!-- only percentual -->
            <app-activity-preview [parent]="parent" [grandParent]="grandParent" [name]="activity.name"
              *ngIf="activity.questions.length > 1 || activity.questions[0].type === 'CONTINUOUS_NUMERIC_ANSWER'"
              [hidden]="_step - 1 !== idx" #activityPreview [order]="activity.order" [statement]="activity.description"
              [type]="'CONTINUOUS_NUMERIC_ANSWER'" [video]="" [options]="activity.questions"
              [min]="activity.options_min" [max]="activity.options_max" [edit]="activity.questions">
            </app-activity-preview>

            <!-- all except percentual -->
            <app-activity-preview [parent]="parent" [grandParent]="grandParent" [name]="activity.name"
              *ngIf="activity.questions.length === 1 && activity.questions[0].type !== 'CONTINUOUS_NUMERIC_ANSWER'"
              [hidden]="_step - 1 !== idx" #activityPreview [order]="activity.questions[0].order"
              [statement]="activity.questions[0].description" [type]="activity.questions[0].type"
              [video]="activity.questions[0].video_url" [options]="activity.questions[0].options"
              [min]="activity.questions[0].options_min" [max]="activity.questions[0].options_max"
              [edit]="activity.questions[0].answers">
            </app-activity-preview>
          </div>
        </div>

        <!-- ACTIONS -->
        <div class=" activity-footer">
          <div class="activity-progress">
            <ngb-progressbar type="success" [value]="_step | percentBy: activities.length"></ngb-progressbar>
            <span class="font-14 opacity-50">{{_step}} / {{activities.length}}</span>
          </div>
          <div class="activity-actions">
            <button class="btn" (click)="prevStep()" [disabled]="!currentStep">Atrás</button>
            <button class="btn" (click)="save()" [disabled]="inProcess">
              <span *ngIf="inProcess">Cargando</span>
              <span *ngIf="!inProcess">Continuar</span>
            </button>
          </div>
        </div>
      </article>

      <!-- COMPLETED -->
      <article card__content [hidden]="!subthemeCompleted || editMode">

        <span *ngIf="parent" class="d-inline-block p-3 borda-10 bg-grey-light text-blue font-primary--b activity-badge">
          Subtema {{parent.order}} - {{ parent.name }}
        </span>
        <div class="activity-content text-center my-4">
          <img class="img-fluid success-icon mb-3" src="/assets/images/icons/icon_success.png" alt="">
          <h2 *ngIf="isPreliminarLimit && !isSubscribed" class="font-18 font-primary--b mb-2">Felicidades, has terminado
            la versión gratuita.
          </h2>
          <h2 *ngIf="isChapterEnd && isSubscribed" class="font-18 font-primary--b mb-2">Felicidades, has completado esta
            temporada.
          </h2>
          <h2 class="font-18 font-primary--b">¡Actividad completada!</h2>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="activity-progress">
          </div>
          <div>
            <button class="btn" (click)="toggleEditMode()">Revisar</button>
            <button class="btn" (click)="_nextPage()"
              *ngIf="(!isPreliminarLimit || isSubscribed) && !isChapterEnd">Continuar</button>
            <button class="btn bg-red" routerLink="/dashboard/finished-chapter"
              *ngIf="isPreliminarLimit && !isSubscribed">
              <span class="text-white">Finalizar Versión Gratuita</span>
            </button>
            <button class="btn bg-red" routerLink="/dashboard/finished-chapter" *ngIf="isChapterEnd && isSubscribed">
              <span class="text-white">Finalizar Temporada</span>
            </button>
          </div>

        </div>
      </article>


    </app-card>
  </div>

  <ngb-alert type="danger" class="mt-3" *ngIf="errors.length" (closed)="errors = []">
    <ul>
      <li *ngFor="let error of errors">{{error}}</li>
    </ul>
  </ngb-alert>
</section>