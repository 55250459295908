<form class="form">
    <h5 class="color-blue"><b>Cambiar contraseña</b></h5>
    <br>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
        <div class="input-row">
            <label>Contraseña Actual</label>
            <input [(ngModel)]="security_data.password" name="password" class="input"
                [type]="show_password ? 'text' : 'password'">
            <i class="bi eye-icon" [class.bi-eye-fill]="!show_password" [class.bi-eye-slash-fill]="show_password"
                (click)="show_password = !show_password">
            </i>
        </div>
    </div>
    <br>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
        <div class="input-row">
            <label>Nueva Contraseña</label>
            <input [(ngModel)]="security_data.new_password" name="new_password" class="input"
                [type]="show_new_password ? 'text' : 'password'"
                (ngModelChange)="checkForPasswordMatch(security_data.new_password)">
            <i class="bi eye-icon" [class.bi-eye-fill]="!show_new_password"
                [class.bi-eye-slash-fill]="show_new_password" (click)="show_new_password = !show_new_password"></i>
        </div>
    </div>
    <br>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
        <div class="input-row">
            <label>Confirma Nueva Contraseña</label>
            <input [(ngModel)]="security_data.new_password2" name="confirm_password" class="input"
                (ngModelChange)="checkToContinue()" [type]="show_confirm_password ? 'text' : 'password'">
            <i class="bi eye-icon" [class.bi-eye-fill]="!show_confirm_password"
                [class.bi-eye-slash-fill]="show_confirm_password"
                (click)="show_confirm_password = !show_confirm_password"></i>
        </div>
    </div>
    <div class="input-row text-error"
        *ngIf="security_data.new_password && security_data.new_password2 && (security_data.new_password2.length > 7) && (security_data.new_password2 !== security_data.new_password)">
        <div></div>
        <p class="text-red">Las contraseñas no coinciden</p>
    </div>
    <div class="input-row">
        <div></div>
        <p class="hint">
            <b>Ten en cuenta que la nueva contraseña debe contener:</b>
            <br>
            <i class="bi bi-check text-blue-light" *ngIf="security_data.new_password.length > 7"></i>
            Mínimo 8 caracteres<br>
            <i class="bi bi-check text-blue-light" *ngIf="password_match_lowercase"></i>Al menos una letra minúscula<br>
            <i class="bi bi-check text-blue-light" *ngIf="password_match_uppercase"></i>Al menos una letra mayúscula<br>
            <i class="bi bi-check text-blue-light" *ngIf="password_match_number || password_match_special_chars"></i>
            Al menos un númnero o un caracter especial
        </p>
    </div>
    <br>
    <div class="col col-12 col-md-12 col-sm-12 col-lg-12">
        <button class="btn btn-primary float-end" (click)="changePassword()" [disabled]="in_progress || !can_continue">
            {{ in_progress ? 'Guardando' : 'Guardar' }} contraseña
            <span *ngIf="in_progress" class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>