import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth.service';
import { UserService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input('user_data') user_data: any;

  constructor(private userService: UserService, private authService: AuthService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {
    let token = this.cookieService.get("gr_access");
    let decode_token = this.authService.getDecodedAccessToken(token);
    this.userService.getUserProfileData(decode_token.user_id).subscribe((user_data: any) => {
      this.user_data = { ...user_data };
    })
  }

}
