import { FormGroup } from "@angular/forms";

const KEY_ERRORS: any = {
  maxlength: 'Superaste el limite de caracteres',
  minlength: 'No supera el minimo de caracteres',
  required: 'Campo requerido',
  pattern: 'Campo no valido',
  email: 'Debe ser un correo electrónico valido'
}

const handleKeyErrorLabel = (keyError: string) => {
  return KEY_ERRORS[keyError]
}

export const handleFormErrors = (formControls: FormGroup<any>) => {
  let totalErrors = 0
  let errors: any = {}

  Object.keys(formControls.controls).forEach(key => {
    const controlErrors = formControls.get(key)?.errors

    if (controlErrors != null) {
       totalErrors++;

       Object.keys(controlErrors).forEach(keyError => {
        errors[key] = {
          ...controlErrors[keyError],
          label: handleKeyErrorLabel(keyError)
        }
      })
    }
  })

  return { totalErrors, errors }
}
