export class IncrementValue {
  static readonly type = '[Item] Increment value by one';
}

export class DecrementValue {
  static readonly type = '[Item] Decrement value by one';
}

export class ResetValue {
  static readonly type = '[Item] Reset value';
}

export class SetValue {
  static readonly type = '[Item] Set value defined by user';
  constructor(public payload: number) { }
}
