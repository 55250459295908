<div *ngIf="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
  <app-loading color="#DE377C"></app-loading>
</div>

<main
  class="{{ (userFinishedChapter && userIsSuscribed) ? 'finished-page-double' : 'finished-page'  }} page--full"
  *ngIf="!isLoading"
>
  <section class="greetings-wrapper" *ngIf="userFinishedChapter">
    <app-vertical-stepper
      [vocationalParameters]="userVocationalInterestsParameters"
      [reportUrlToDownload]="reportUrlToDownload"
    ></app-vertical-stepper>
  </section>
  
  <section class="membership-wrapper bg-grey-light" *ngIf="userIsSuscribed">
    <div class="content-wrapper" [style]="{backgroundImage: 'url(/assets/images/patterns/pattern_membership.png)'}">
      <app-membership-card></app-membership-card>
    </div>
  </section>
</main>
