<main class="home-page page">
  <!-- HEADER -->
  <section>
    <app-header></app-header>
  </section>

  <!-- CONTENT -->
  <section *ngIf="true">
    <div class="row gap-2 m-0">
      <!-- RESUME PROGRESS -->
      <div class="col-12 col-lg-auto">
        <div class="card chart-card h-100">
          <h2 class="font-25 font-primary--b text-center text-lg-start">Mi Progreso</h2>
          <section class=" circle-wrapper d-flex justify-content-center">
            <circle-progress [percent]="userProgress.progress_percentage" [options]="circleProgressOption">
            </circle-progress>
          </section>
          <h3>
            <span class="d-block font-15 font-primary--b mb-2 mt-3 mt-lg-3 text-center text-lg-start"
              *ngIf="userProgress.progress_percentage > 0">
              ¡Buen trabajo!
            </span>
            <span class="d-block font-15 font-primary--b mb-2 mt-3 mt-lg-3 text-center text-lg-start"
              *ngIf="userProgress.progress_percentage == 0">
              ¡Es el momento!
            </span>
            <span class="d-block font-15 opacity-50 text-center text-lg-start"
              *ngIf="userProgress.progress_percentage > 0">
              Sigue así y termina el 100% <br> de orientación vocacional.
            </span>
            <span class="d-block font-15 opacity-50 text-center text-lg-start"
              *ngIf="userProgress.progress_percentage == 0">
              Comienza ahora y descubre <br> tu orientación vocacional.
            </span>
          </h3>
        </div>
      </div>
      <!-- GRAPHS -->
      <div class="col">
        <div class="card chart-card h-100">
          <div class="col-12 stadistics">
            <section class="d-flex align-items-center justify-content-between">
              <h2 class="font-25 font-primary--b mr-3">Estadísticas &nbsp;&nbsp;
                <span class="font-15 opacity-50">Datos generados con IA</span>
              </h2>
              <button class="btn" routerLink="/dashboard/progress">
                <span class="text-red font-15">Ver Todo</span>
              </button>
            </section>
          </div>
          <div class="row">
            <!-- VOCATIONAL INTEREST -->
            <div class="col-12 col-lg-6">
              <h3 class="font-15 font-primary--b">Tus Intereses Vocacionales</h3>
              <section>
                <app-radar-chart *ngIf="show_charts" [inDashboard]="true" [chartCategory]="'vocational_interests'"
                  [chartId]="'vocational_interests_chart'">
                </app-radar-chart>
              </section>
            </div>
            <!-- PROFESIONAL APTITUDES -->
            <div class="col-12 col-lg-6">
              <h3 class="font-15 font-primary--b">Tus Aptitudes Profesionales</h3>
              <section style="margin-top:20px;">
                <app-doughnut-chart *ngIf="show_charts" [chartId]="'profesionalChart'" [classOverlay]="true"
                  [chartCategory]="'professional_fields'">
                </app-doughnut-chart>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <br><br>

  <!-- CAROUSEL VIDEOS -->

  <!-- chapters.length -->
  <section *ngIf="chapters.length" class="carousel">
    <div class="carousel__header row justify-content-between align-items-center">
      <p class="carousel__head col-auto font-25 font-primary--b">Continuar viendo</p>
      <div class="carousel__controls col-auto">
        <button title="Capítulo anterior" class="btn" (click)="chaptersCarousel.prev()">
          <img class="img-fluid" [src]="'/assets/images/icons/icon_prev.svg'" alt="">
        </button>
        <button title="Capítulo siguiente" class="btn" (click)="chaptersCarousel.next()">
          <img class="img-fluid" [src]="'/assets/images/icons/icon_next.svg'" alt="">
        </button>
      </div>
    </div>
    <div class="carousel-wrapper">
      <owl-carousel-o [options]="customOptions" #chaptersCarousel>
        <ng-template carouselSlide *ngFor="let chapter of chapters ; index as chapterIndex">
          <div class="slide custom-slide">
            <app-panel [chapter]="chapter" [chapterNumber]="chapterIndex" [userSubscribed]="userData.is_subscribed">
            </app-panel>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>

  <!-- NOT CHAPTERS -->
  <app-card padding="50px 25px" borderRadius="10px" boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 1px" *ngIf="!chapters.length">
    <article card__content class="row">
      <div class="col-12 text-center">
        <img class="opacity-25 mb-4" src="/assets/images/icons/icon_warning.png" alt="">
        <h2 class="font-18 font-primary--b text-grey opacity-50">
          No se han publicado temporadas aun.
        </h2>
      </div>
    </article>
  </app-card>
</main>
