<nav class="playlist">
  <ul class="items list-unstyled">
    <li class="item cursor-pointer" *ngFor="let theme of themes">
      <!-- HEADER -->
      <button class="header d-flex btn w-100" (click)="selectPage(theme)" title="ir a {{theme.name}}"
        routerLink="/dashboard/seasons/1/chapters/{{chapterId}}/theme/{{theme.id}}">
        <h2 class="main-title" [class.active]="theme.id === pageSelected!.parent || theme.id === pageSelected!.id">Tema
          {{theme.order}}: {{ theme.name }}</h2>

        <button type="button" class="btn" title="{{theme.name}}">
          <app-radio [isActive]="theme.id === pageSelected!.parent || theme.id === pageSelected!.id"
            [color]="'#00CAA8'">
          </app-radio>
        </button>
      </button>
      <!-- BODY -->
      <!-- TODO: IMPROVE ANIMATION -->
      <div class="content" *ngIf="theme.id === pageSelected!.parent || theme.id === pageSelected!.id">
        <ul class="subitems list-unstyled">
          <li routerLink="/dashboard/seasons/1/chapters/{{chapterId}}/theme/{{theme.id}}/subtheme/{{subtheme.id}}"
            class="subitem" *ngFor="let subtheme of theme.pages" (click)="selectPage(subtheme)">

            <button *ngIf="subtheme.progress.progress_percentage < 100" title="ir a {{subtheme.name}}"
              class="thumbnail-wrapper border-0" [class.background]="subtheme.image"
              [style]="{'backgroundImage': 'url('+apiUrl+subtheme.image+')'}">
            </button>
            <button title="Actividades completadas" class="thumbnail-wrapper completed border-0"
              *ngIf="subtheme.progress.progress_percentage === 100">
              <img src="/assets/images/icons/icon_check_white.svg" alt="">
            </button>

            <h3 class="title cursor-pointer" [class.enabled]="subtheme.progress.progress_percentage === 100">
              {{subtheme.order}}. {{subtheme.name}}</h3>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</nav>
