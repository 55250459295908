import { Chapter } from './../../../models/chapter.models';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seasons-page',
  templateUrl: './seasons-page.component.html',
  styleUrls: ['./seasons-page.component.scss']
})
export class SeasonsPageComponent implements OnInit {
  chapters: Array<Chapter> = [
    {
      id: '1',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: true,
      progress: 0,
      bgGradient: 'linear-gradient(140deg, #00CAA8 0%, #078CC7 100%)',
      bgImage: '',
    },
    {
      id: '2',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: false,
      progress: 0,
      bgGradient: 'linear-gradient(140deg, #00CAA8 0%, #078CC7 100%)',
      bgImage: '',
    },
    {
      id: '3',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: false,
      progress: 0,
      bgGradient: 'linear-gradient(136deg, #F5C17D 0%, #FF3131 100%)',
      bgImage: '',
    },
    {
      id: '4',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: false,
      progress: 0,
      bgGradient: 'linear-gradient(136deg, #F5C17D 0%, #FF3131 100%)',
      bgImage: '',
    },
    {
      id: '5',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: false,
      progress: 0,
      bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
      bgImage: '',
    },
    {
      id: '6',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: false,
      progress: 0,
      bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
      bgImage: '',
    },
    {
      id: '7',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: false,
      progress: 0,
      bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
      bgImage: '',
    },
    {
      id: '8',
      title: 'Titulo del capitulo',
      completedThemes: 0,
      totalThemes: 3,
      enabled: false,
      progress: 0,
      bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
      bgImage: '',
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
