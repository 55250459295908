import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Chapter } from 'src/app/models/chapter.models';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  getUserProfileMenuOotions() {
    let options = [
      { active: false, title: 'CUENTA', id: -1, type: 'header', icon: null },
      { active: true, title: 'Información personal', id: 0, type: 'option', icon: 'bi-person-badge' },
      { active: false, title: 'Seguridad', id: 1, type: 'option', icon: 'bi-shield-shaded' },
      { active: false, title: 'Preferencias', id: 2, type: 'option', icon: 'bi-sliders' },
      { active: false, title: 'SUSCRIPCIONES', id: -1, type: 'header', icon: null },
      { active: false, title: 'Gestionar suscripciones', id: 3, type: 'option', icon: 'bi-bookmark-star-fill' },
      { active: false, title: 'PAGOS', id: -1, type: 'header', icon: null },
      { active: false, title: 'Medios de pago', id: 4, type: 'option', icon: 'bi-credit-card' },
      { active: false, title: 'Historial de pagos', id: 5, type: 'option', icon: 'bi-card-list' },
    ]

    return options;
  }

  getUserPaymentMethods() {
    let payment_methods = [
      {
        id: 0,
        name: 'Daniel Elias Zeait',
        brand: "visa",
        exp_month: 11,
        exp_year: 2023,
        is_default: true,
        last4: "6789",
      },
      {
        id: 1,
        name: 'Daniel E Zeait Abimussa',
        brand: "mastercard",
        exp_month: '08',
        exp_year: 2023,
        is_default: false,
        last4: "6789",
      },
    ]

    return payment_methods
  }

  getMonthOptions() {
    let months = Array.from({ length: 12 }, (_, i) => i + 1);
    return months;
  }

  getYearOptions() {
    let date = new Date();
    let year = date.getFullYear();
    let stop = year + 50;
    let years = Array.from({ length: (stop - year) / 1 + 1 }, (_, i) => year + (i * 1));
    return years;
  }

  getDummyChapters() {
    let chapters: Array<Chapter> = [
      {
        id: '1',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: true,
        progress: 0,
        bgGradient: 'linear-gradient(140deg, #00CAA8 0%, #078CC7 100%)',
        bgImage: '',
      },
      {
        id: '2',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(140deg, #00CAA8 0%, #078CC7 100%)',
        bgImage: '',
      },
      {
        id: '3',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #F5C17D 0%, #FF3131 100%)',
        bgImage: '',
      },
      {
        id: '4',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #F5C17D 0%, #FF3131 100%)',
        bgImage: '',
      },
      {
        id: '5',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
      {
        id: '6',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
      {
        id: '7',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
      {
        id: '8',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
      {
        id: '9',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
      {
        id: '10',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
      {
        id: '11',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
      {
        id: '12',
        title: 'Titulo del capitulo',
        completedThemes: 0,
        totalThemes: 3,
        enabled: false,
        progress: 0,
        bgGradient: 'linear-gradient(136deg, #90AAED 0%, #9E3678 100%)',
        bgImage: '',
      },
    ]
    return chapters;
  }

  getChartLabels(category: any) {
    let labels: any = [];
    if (category == 'vocational_interests') {
      labels = [
        'Técnico',
        'Investigador',
        'Emprendedor',
        'Comunicador',
        'Analista'
      ];
    } else if (category == 'professional_fields') {
      labels = [
        "Técnico-Manual",
        "Científico-Investigador",
        "Artístico-Creativo",
        "Social-Asistencial",
        "Empresarial-Persuasivo",
        "Administración-Gestión",
        "Digital/Virtual-Streamer"
      ];
    } else if (category == 'personalities') {
      labels = [
        "Perfeccionista",
        "Altruista",
        "Activo",
        "Affectivo",
        "Racional",
        "Escéptico",
        "Entusiásta",
        "Liderazgo",
        "Mediador"
      ]
    } else if (category == 'multiple_intelligences') {
      labels = [
        "Lingüistica",
        "Lógica matemática",
        "Espacial",
        "Cinética - Corporal",
        "Musical",
        "Intrapersonal",
        "Interpersonal",
        "Naturalista"
      ]
    } else if (category == 'intelligences') {
      labels = [
        "Autoconciencia",
        "Empatía",
        "Autoconfianza",
        "Motivación",
        "Autocontrol",
        "Competencia Social"
      ]
    } else if (category == 'learning_styles') {
      labels = [
        "Activo",
        "Reflexivo",
        "Teórico",
        "Pragmático",
      ]
    }
    return labels;
  }

  getChartColors(category: any) {
    let colors: any;
    if (category == 'vocational_interests') {
      colors = {
        backgroundColor: '#FF9E6866',
        borderColor: '#FF9E68',
        pointBackgroundColor: '#FFD3BB',
        pointBorderColor: '#FF9E68',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }
    } else if (category == 'professional_fields') {
      colors = [
        '#B767FD',
        '#00CAA8',
        '#2194FF',
        '#FF9E68',
        '#FFC950',
        '#FA4D4D',
        '#C02BC0',
      ]
    } else if (category == 'personalities') {
      colors = {
        backgroundColor: '#00CAA866',
        borderColor: '#00CAA8',
        pointBackgroundColor: '#00CAA866',
        pointBorderColor: '#00CAA8',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#300CAA8'
      }
    } else if (category == 'multiple_intelligences') {
      colors = [
        '#b47de4',
        '#00CAA8',
        '#2194FF',
        '#FF9E68',
        '#FFC950',
        '#FA4D4D',
        '#C02BC0',
        '#528742',
      ]
    } else if (category == 'intelligences') {
      colors = {
        background: [],
        borders: []
      }
      colors.background = [
        '#DE377C66',
        '#00CAA866',
        '#2194FF66',
        '#FF9E6866',
        '#FFC95066',
        '#FA4D4D66',
      ];
      colors.borders = [
        '#DE377C66',
        '#00CAA866',
        '#2194FF66',
        '#FF9E6866',
        '#FFC95066',
        '#FA4D4D66',
      ]
    } else if (category == 'learning_styles') {
      colors = [
        '#00CAA8',
        '#DE377C',
        '#FF9E68',
        '#0058FF',
      ]
    }
    return colors;
  }
}
