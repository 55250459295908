<div class="container">
  <br>
  <div class="row">
    <div class="col-12 col-6 col-sm-12 col-md-12 col-lg-6">
      <h1 class="font-secondary--b font-38 text-left">
        Programa Completo <span class="text-red">Growthroad</span>
        <div class="icon d-inline-block">
          <img class="img-fluid" style="margin-left:10px" src="/assets/images/icons/icon_crown.svg" alt="">
        </div>
      </h1>
      <small class="text-left d-block text-grey-alt font-16 mb-3 opacity-40">
        Programa completo con todos los beneficios.
      </small>
      <br>
      <p class="w-85 font-18 font-secondary--b" style="line-height: 2em;">
        {{
        user_subscriptions && user_subscriptions.length == 0 ?
        'Actualmente no te encuentras suscrito al Programa Completo de Growthroad.' :
        'FELICIDADES. Ya te encuentras suscrito al Programa Completo de Growthroad.'
        }}
      </p>
      <br>
      <p class="w-85" style="line-height: 2em;" *ngIf="user_subscriptions && user_subscriptions.length == 0">
        Todos los capítulos de orientación vocacional, reportes personalizados y experiencias premium son algunas de las
        herramientas que encontrarás en este programa.
        Accede ahora y comienza a disfrutar de todos los beneficios que Growthroad ofrece para ti.
      </p>
      <div class="w-85" style="line-height: 2em;" *ngIf="user_subscriptions && user_subscriptions.length > 0">
        <ul class="features">
          <li class="feature" *ngFor="let feature of features">
            <span class="title font-15 font-secondary">
              <i class="bi bi-check text-blue-light font-20"></i> &nbsp; {{ feature.title }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-6 col-sm-12 col-md-12 col-lg-6">
      <section class="membership-wrapper">
        <div class="content-wrapper">
          <app-card borderRadius="15px" padding="35px 50px">
            <article card__content *ngIf="user_subscriptions && user_subscriptions.length == 0">

              <div class="head">
                <h4 class="text-left d-block font-18 mb-2 opacity-20">
                  Pago único
                </h4>
                <h3 class="text-left font-22 font-secondary text-grey-alt">
                  <span class="font-secondary--eb font-49 text-blue-dark">{{productPrice}} </span> <span
                    class="text-grey-alt opacity-40">+
                    IVA</span>
                </h3>
                <small class="text-left d-block text-grey-alt font-15 mb-3 opacity-40">
                  Hasta 15 días de cancelación.
                </small>
              </div>
              <hr>

              <div class="content">
                <ul class="features">
                  <li class="feature" *ngFor="let feature of features">
                    <span class="title font-15 font-secondary">
                      <i class="bi bi-check text-blue-light font-20"></i> &nbsp; {{ feature.title }}
                    </span>
                  </li>
                </ul>
                <br>
                <button class="bg-red btn font-btn w-100" [routerLink]="['/dashboard/checkout/1']">
                  <span class="font-secondary--b font-20 text-white">¡Acceder ahora!</span>
                </button>
              </div>
            </article>
            <!-- If user is subscribed -->
            <article card__content *ngIf="user_subscriptions && user_subscriptions.length > 0">
              <div class="content subscription-details">
                <!-- <h2 class="text-left d-block font-24 mb-2 font-primary--b">
                        Detalles de mi suscripción
                      </h2>
                      <hr> -->
                <h2 class="text-center font-42 font-primary--eb">
                  <span class="text-blue">{{ user_subscriptions[0].left_days }} </span>
                </h2>
                <h2 class="text-center font-18" style="margin-top: -5px !important;"> días restantes </h2>
                <br>
                <div class="progress">
                  <div class="progress-bar progress-bar-striped" role="progressbar"
                    [style.width]="user_subscriptions[0].progress + '%'" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <br>
                <h3 class="text-left font-15 font-secondary text-grey-alt">
                  <span class="font-secondary text-blue-dark">Válida desde: </span>
                  <span class="float-end font-primary--b">{{ user_subscriptions[0].start_at | customDatePipe }}</span>
                </h3>
                <h3 class="text-left font-15 font-secondary text-grey-alt">
                  <span class="font-secondary text-blue-dark">Válida hasta: </span>
                  <span class="float-end font-primary--b">{{ user_subscriptions[0].finish_at | customDatePipe }}</span>
                </h3>
                <h3 class="text-left font-15 font-secondary text-grey-alt">
                  <span class="font-secondary text-blue-dark">Estatus: </span>
                  <span class="float-end font-primary--b">{{ user_subscriptions[0].is_active ? 'Activa' : 'Cancelada'
                    }}</span>
                </h3>
                <!-- <h3 class="text-left font-15 font-secondary text-grey-alt">
                        <span class="font-secondary text-blue-dark">Monto cancelado: </span>
                        <span class="float-end font-primary--b">{{ user_subscriptions[0].product.price | number:'1.2-2' }} €</span>
                      </h3> -->
                <!-- <h3 class="text-left font-15 font-secondary text-grey-alt">
                        <span class="font-secondary text-blue-dark">Días restantes: </span>
                        <span class="float-end font-primary--b">{{ user_subscriptions[0].left_days  }}</span>
                      </h3> -->
              </div>
              <hr>
              <br>
              <button disabled class="bg-blue btn font-btn w-100">
                <span class="font-secondary font-16 text-white">Cancelar suscripción</span>
              </button>
              <br><br>
              <button disabled class="bg-red btn font-btn w-100">
                <span class="font-secondary font-16 text-white">Renovar suscripción</span>
              </button>
            </article>
          </app-card>

        </div>
      </section>
    </div>
  </div>
</div>