import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartType } from 'chart.js';
import { Chart, registerables } from 'chart.js';
import { ChartService } from 'src/app/services/charts/charts.service';
Chart.register(...registerables);

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {
  @ViewChild('chart') chartCanvas:ElementRef | undefined;
  @Input('inDashboard') isInDashboard:any;
  @Input() type: ChartType = 'radar'
  @Input() labels: Array<string> = ['Lorem', 'Lorem', 'Lorem', 'Lorem']
  @Input() dataSets!: Array<any>;

  chart:any;

  constructor(private chartService:ChartService) { }

  ngOnInit(): void {
    this.getChartData();
  }
  
  getChartData(){
    this.dataSets = this.chartService.getChartDataByCategory('vocational_interests');
    this.initChart();
  }

  initChart(){
    var canvas : any = document.getElementById("chart");
    var ctx2 = canvas.getContext("2d");
    
    this.chart = new Chart(ctx2, {
      type: 'radar',
      data: {
        labels:this.labels,
        datasets: [
          {
            data:this.dataSets,
            fill:true,
            backgroundColor: '#FF9E6866',
            borderColor: '#FF9E68',
            pointBackgroundColor: '#FFD3BB',
            pointBorderColor: '#FF9E68',
            pointRadius:4,
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          }
        ]
      },
      
      options: {
        responsive: true,
        scales: {
          r: {
            ticks: {
              display:false
            },
            angleLines: {
              color: '#aaa'
            },
            grid: {
              color:'#ccc',
              borderDash:[12,12]
            },
            pointLabels:{
              color:'#666',
              font:{
                size:12,
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      },
    });
  }

}
